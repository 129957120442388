import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Seo from "src/components/Core/Seo";
import ArticleList from "src/components/Homepage/ArticleList";

import PageBlock from "../components/Core/PageBlock";
import Layout from "../components/Layout";

const Index: FC<PageProps<Gatsby.HomepageQuery>> = ({
  data: {
    allDatoCmsArticle: { articles },
    page,
  },
}) => {
  const { blocks, ctaCard, seo, hotjar } = page!;

  return (
    <Layout>
      <Seo hotjar={hotjar} path="/" seo={seo!} />

      <ArticleList
        articles={[...articles!]}
        ctaCard={ctaCard}
      />

      {blocks?.map((block) => {
        return <PageBlock {...block!} key={block?.id} />;
      })}
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query Homepage {
    page: datoCmsHomePage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      hotjar
      ctaCard {
        ...DatoCmsCtaCard
      }
      blocks {
        ...DatoCmsCollectionCarousel
        ...DatoCmsGlobalCtaBlock
        ...DatoCmsMostReadArticlesBlock
        ...DatoCmsPracticalToolsBlock
        # ...DatoCmsGuidesCarousel
      }
    }
    allDatoCmsArticle(limit: 16, sort: { fields: meta___firstPublishedAt, order: DESC }) {
      articles: nodes {
        ...DatoCmsArticleCard
      }
    }
  }
`;

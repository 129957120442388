import Seo from "src/components/Core/Seo";
import { breadcrumbMarkup, personMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import { getAuthorLink } from "src/services/author/link";

import Breadcrumbs from "../Core/Breadcrumbs";

import ArticleList from "./ArticleList";
import Favorites from "./Favorites";
import Intro from "./Intro";

type AuthorType = {
  pageIndex: number;
  author: Gatsby.DatoCmsAuthor | null;
  firstArticle: Array<{ id: string; title: string; slug: string; meta: { firstPublishedAt: string } }>;
  latestArticles: Gatsby.DatoCmsArticleCardFragment[];
  seo: Gatsby.GatsbyDatoCmsSeoMetaTagsFragment | null;
  totalPages: number;
  path: string;
};

const Author = ({
  pageIndex,
  author,
  firstArticle,
  latestArticles,
  seo,
  totalPages,
  path
}: AuthorType) => {
  if (!author) return null;

  const link = getAuthorLink(author.slug!);
  const name = `${author.name}${author.job ? `, ${author.job}` : ''}`

  const imageObjectMarkup = author.image ? {
    '@id': `${process.env.GATSBY_SHINE_URL}#/schema/person/image/`,
    url: author.image.url,
    contentUrl: author.image.url,
    width: author.image.width,
    height: author.image.height,
    caption: author.image.alt,
  } : null;

  return (
    <Layout>
      <Seo
        breadcrumbMarkup={breadcrumbMarkup([], { name: author.name!, path: link })}
        {...(author.image ? { imageObjectMarkup } : {})}
        path={path}
        personMarkup={personMarkup(author)}
        seo={seo!}
        webPageMarkup={{
          name,
          description: author.description }}
      >
        <title>{name}</title>

        <meta content={author.description} name="description" />
      </Seo>

      <Breadcrumbs
        current={{
          title: author.name,
          link
        }}
        mb="6"
      />

      <Intro author={author} firstArticle={firstArticle} />

      {author.favorites?.length ? <Favorites articles={author.favorites!} /> : null}

      <ArticleList
        articles={latestArticles}
        link={link}
        pageIndex={pageIndex}
        totalPages={totalPages}
      />
    </Layout>
  );
};

export default Author;

import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Structure from "src/components/Article/Structure";
import { getCategoryLevel } from "src/services/article/category";
import { getArticleLink } from "src/services/article/link";
import { getBreadcrumbs } from "src/services/categories/breadcrumbs";

const Article: FC<PageProps<Gatsby.ArticleQuery>> = ({
  data: { datoCmsArticle, globalCta, relatedArticles, mostReadArticles, mobileCta },
}) => {
  if (!datoCmsArticle) return null;

  const upperCategories = datoCmsArticle.primaryCategory
    ? getCategoryLevel(datoCmsArticle.primaryCategory)
    : [];

  const crumbs = getBreadcrumbs(upperCategories);

  return (
    <Structure
      article={datoCmsArticle}
      crumbs={crumbs}
      globalCta={globalCta!}
      mobileCta={mobileCta}
      mostReadArticles={mostReadArticles!}
      path={getArticleLink(datoCmsArticle.slug!)}
      relatedArticles={relatedArticles.nodes!}
    />
  );
};

export default Article;

export const query = graphql`
  query Article($id: String, $relatedArticlesIds: [String]) {
    datoCmsArticle(originalId: { eq: $id }) {
      ...ArticleData
    }
    relatedArticles: allDatoCmsArticle(
      filter: { originalId: { in: $relatedArticlesIds } }
    ) {
      nodes {
        ...RelatedArticles
      }
    }
    mostReadArticles: datoCmsMostReadArticle {
      ...DatoCmsMostReadArticle
    }
    globalCta: datoCmsGlobalCta {
      ...DatoCmsGlobalCta
    }
    mobileCta: datoCmsStickyMobileCta(relatedArticles: { elemMatch: { originalId: { eq: $id } } }) {
      ...StickyMobileCta
    }
  }
`;


import {
  Box,
  Button,
  Image,
  VStack,
  Text,
  Link,
  ButtonGroup,
  StackProps,
} from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";

const CtaCard: FC<Gatsby.DatoCmsCtaCardFragment & StackProps> = ({
  image,
  brandLogo,
  copy,
  ctaLink,
  ctaLabel,
}) => {
  return (
    <ButtonGroup cursor="pointer">
      <VStack
        bg="white"
        borderRadius="3xl"
        justify="center"
        p={{ base: 2 }}
        spacing={{ base: 4, md: 6 }}
        w="full"
      >
        <Box h={{ base: "70px", md: "120px" }}>
          <Image
            as={GatsbyImage}
            h={"100%"}
            image={image?.gatsbyImageData}
            imgStyle={{ objectFit: "contain" }}
          />
        </Box>

        <VStack spacing={{ base: 4, md: 6 }}>
          <Image
            alt={brandLogo?.alt || ""}
            maxH={{ base: "25px", md: "47px" }}
            mx={{ md: "auto" }}
            src={brandLogo?.url}
          />

          <Text
            fontSize={{ base: "font-16", lg: "font-24" }}
            fontWeight="bold"
            lineHeight="shorter"
            textAlign="center"
            variant="article"
          >
            {copy}
          </Text>

          <Button as={Link} href={ctaLink}>
            {ctaLabel}
          </Button>
        </VStack>
      </VStack>
    </ButtonGroup>
  );
};

export default CtaCard;

import { buildClient } from '@datocms/cma-client-browser';

const client = buildClient({ apiToken: process.env.GATSBY_DATO_CMS_SITE_SEARCH_API_TOKEN });

//BUILD_TRIGGER_ID can be found in DatoCMS dashboard > Settings > Build triggers
//For more information please check out this documentation below :
//https://www.datocms.com/docs/site-search/configuration
const BUILD_TRIGGER_ID = '16217'
const LOCALE = "fr"

async function getSearchResult(search: string) {
  const { data: searchResults } = await client.searchResults.rawList({
    filter: {
      query: search,
      build_trigger_id: BUILD_TRIGGER_ID,
      locale: LOCALE,
      fuzzy: true
    },
  });

  return searchResults
}

export { getSearchResult };

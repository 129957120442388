import { Box, Flex, Container, ContainerProps } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import { FC } from "react";
import { ArrowButton, InternalLink } from "src/atoms";
import Carousel from "src/components/Core/Carousel";

import CollectionCard from "../CollectionCard";

const Collections: FC<ContainerProps> = ({ ...rest }) => {
  const { datoCmsGlobalCollectionsList, allDatoCmsCollection } =
    useStaticQuery<Gatsby.DatoCmsGlobalCollectionsListQuery>(
      graphql`
        query DatoCmsGlobalCollectionsList {
          datoCmsGlobalCollectionsList {
            title
            ctaLink {
              slug
            }
            ctaLabel
          }
          allDatoCmsCollection {
            nodes {
              ...DatoCmsCollectionCard
            }
          }
        }
      `
    );

  if (!datoCmsGlobalCollectionsList) return null;

  const { ctaLink, ctaLabel, title } = datoCmsGlobalCollectionsList;

  const link = ctaLink?.slug || "";

  return (
    <Container overflow="visible" variant="block" {...rest}>
      <Carousel
        label={ctaLabel}
        length={allDatoCmsCollection.nodes?.length || 0}
        title={title}
        to={link}
      >
        {allDatoCmsCollection.nodes?.map((collection) => {
          return (
            <Box
              flex="1"
              key={collection.id}
              maxW={{
                base: "214px",
                md: "260px",
              }}
            >
              <Flex boxSizing="border-box" h="full">
                <CollectionCard
                  {...collection!}
                  maxW={{
                    base: "214px",
                    md: "260px",
                  }}
                />
              </Flex>
            </Box>
          );
        })}
      </Carousel>

      <Flex
        display={{ base: "flex", lg: "none" }}
        justifyContent="center"
        mt={{ base: 10, md: 9 }}
      >
        <ArrowButton as={InternalLink} to={link}>
          {ctaLabel}
        </ArrowButton>
      </Flex>
    </Container>
  );
};

export default Collections;

import { ListItem } from "@chakra-ui/react";
import { renderRule, isListItem } from "datocms-structured-text-utils";

const list = renderRule(isListItem, ({ children, key }) => (
  <ListItem key={key} marginLeft="6">
    {children}
  </ListItem>
));

export default list;

import { List, OrderedList, UnorderedList } from "@chakra-ui/react";
import { renderRule, isList } from "datocms-structured-text-utils";

const list = renderRule(isList, ({ children, key, node }) => {
  switch (node.style) {
    case "bulleted":
      return (
        <UnorderedList color="text.primary" key={key} my="10" spacing="9">
          {children}
        </UnorderedList>
      );
    case "numbered":
      return (
        <OrderedList
          color="text.primary"
          key={key}
          my="10"
          spacing="9"
        >
          {children}
        </OrderedList>
      );
    default:
      return (
        <List color="text.primary" key={key} my="10" spacing="9">
          {children}
        </List>
      );
  }
});

export default list;

import { VStack, Image, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsContainedImage: FC<Gatsby.DatoCmsContainedImageFragment> = ({
  media,
  caption,
}) => {
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      my={{ base: "6", lg: "10" }}
      overflow="hidden"
      py={{ base: 4, lg: 8 }}
    >
      <Image
        alt={media?.alt || ""}
        as={GatsbyImage}
        image={media?.gatsbyImageData}
        imgStyle={{ borderRadius: "16px" }}
        objectFit="contain"
      />

      {caption && (
        <Text color="text.cta" mt={4} textAlign="center" variant="small">
          {variableTransformer(caption)}
        </Text>
      )}
    </VStack>
  );
};

export default DatoCmsContainedImage;

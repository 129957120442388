const hasPagination = (pathname: string) => {
  const pathnameWithTrailingSlash = pathname.endsWith('/') ? pathname : `${pathname}/`;

  const chunks = pathnameWithTrailingSlash.split('/');
  const lastSlug = chunks[chunks.length - 2];

  if (!lastSlug) {
    return false;
  }

  const isDigitsOnly = /^\d+$/.test(lastSlug);

  return isDigitsOnly;
};

// Should only be called if `hasPagination` returns `true`.
const removePagination = (pathname: string) => {
  const pathnameWithTrailingSlash = pathname.endsWith('/') ? pathname : `${pathname}/`;

  const chunks = pathnameWithTrailingSlash.split('/');
  const newChunks = chunks.slice(0, chunks.length - 2);

  return `${newChunks.join('/')}/`;
};

export { hasPagination, removePagination };

/* eslint-disable @typescript-eslint/no-explicit-any */
function throttle<T extends (...args: any[]) => any>(func: T, limit: number): T {
  let isThrottling: boolean;
  let timeoutId: NodeJS.Timeout;
  let lastRanAt: number;
  
  return function(this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;

    if (!isThrottling) {
      func.apply(context, args);
      lastRanAt = Date.now();
      isThrottling = true;
    } else {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(function() {
        if ((Date.now() - lastRanAt) >= limit) {
          func.apply(context, args);
          lastRanAt = Date.now();
        }
      }, limit - (Date.now() - lastRanAt));
    }
  } as T;
}

export default throttle;

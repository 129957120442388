import CollectionsCarousel from "../CollectionsCarousel";
import GlobalCta from "../GlobalCta";
import GuidesCarousel from "../GuidesCarousel"
import MostReadArticles from "../MostReadArticles";
import PracticalTools from "../PracticalTools";

type Block =
  | Gatsby.DatoCmsMostReadArticlesBlockFragment
  | Gatsby.DatoCmsCollectionCarouselFragment
  | Gatsby.DatoCmsGlobalCtaBlockFragment
  | Gatsby.DatoCmsPracticalToolsBlockFragment
  | Gatsby.DatoCmsGuidesCarouselFragment;

const PageBlock = (block: Block) => {
  switch (block.__typename) {
    case "DatoCmsMostReadArticlesBlock":
      return <MostReadArticles {...block.mostReadArticles!} />;

    case "DatoCmsCollectionCarousel":
      return <CollectionsCarousel />;

    case "DatoCmsGlobalCtaBlock":
      return <GlobalCta {...block.ctaCard!} />;

    case "DatoCmsPracticalToolsBlock":
      return <PracticalTools {...block.tool!} />;

    case "DatoCmsGuidesCarousel":
      return <GuidesCarousel />;

    default: {
      console.warn("UNKNOWN BLOCK TYPE", block);

      return null;
    }
  }
};

export default PageBlock;

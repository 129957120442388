import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from "@chakra-ui/react";
import { FC } from "react";

import * as ICONS from "./icons";

export type CustomIconName = keyof typeof ICONS;

export type CustomIconProps = ChakraIconProps & {
  name: CustomIconName;
  fill?: string;
  boxSize?: number | string;
};

const CustomIcon: FC<CustomIconProps> = ({ name, ...props }) => {
  const refinedName = name.replace(/-/g, "") as keyof typeof ICONS;

  const IconComponent = ICONS[refinedName];

  if (!IconComponent) console.warn(`Unknown icon : ${name}`);

  return (
    <ChakraIcon
      as={IconComponent}
      h="auto"
      w="auto"
      {...props}
    />
  );
};

export default CustomIcon;

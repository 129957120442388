import { Text } from "@chakra-ui/react";
import { isRoot, renderRule, isParagraph } from "datocms-structured-text-utils";

const paragraph = renderRule(isParagraph, ({ children, key, ancestors }) => {
  return (
    <Text
      fontSize="font-20"
      key={key}
      mb={isRoot(ancestors[0]) ? "6" : "0"} //check if pure paragraph
      variant="article"
    >
      {children}
    </Text>
  );
});

export default paragraph;

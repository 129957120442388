import {
  Button,
  ButtonProps,
  ButtonGroup,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FC } from "react";
import ArrowCta from "src/images/ui/arrow-cta.svg";

type ArrowButtonProps = {
  to?: string;
  href?: string;
  arrowSize?: number;
  shine?: boolean;
};

const ArrowButton: FC<ButtonProps & ArrowButtonProps> = (props) => {
  const { children, arrowSize, shine, ...rest } = props;

  return (
    <ButtonGroup>
      <Button
        p={0}
        rightIcon={
          <Flex
            _groupHover={{ transform: "scale(1.14)" }}
            alignItems="center"
            bg="text.primary"
            borderRadius="50%"
            h={arrowSize ? arrowSize : 7}
            justifyContent="center"
            transitionDuration="fast"
            transitionProperty="common"
            transitionTimingFunction="ease-out"
            w={arrowSize ? arrowSize : 7}
          >
            <Image alt="arrow" src={ArrowCta} w="3" />
          </Flex>
        }
        variant="secondary"
        {...rest}
      >
        {children}
      </Button>
    </ButtonGroup>
  );
};

export default ArrowButton;

import {
  UseDisclosureReturn,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { FC } from "react";
import SearchInput from "src/atoms/SearchInput";

import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from "../Header";

type MobileSearchProps = Pick<UseDisclosureReturn, "isOpen" | "onClose">;

const MobileSearch: FC<MobileSearchProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent
        bg="white"
        borderColor="text.primary"
        borderRadius="none"
        borderTop="1px solid"
        h={{
          base: `calc(100vh - ${HEADER_HEIGHT_MOBILE + 1}px)`,
          md: `calc(100vh - ${HEADER_HEIGHT_DESKTOP + 1}px)`,
        }}
        maxW="unset"
        mb="0"
        mt={{
          base: `${HEADER_HEIGHT_MOBILE + 1}px`,
          md: `${HEADER_HEIGHT_DESKTOP+ 1}px`,
        }}
        px={8}
        py={6}
      >
        <SearchInput buttonLeft left="0" />
      </ModalContent>
    </Modal>
  );
};

export default MobileSearch;

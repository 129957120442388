import { Record } from "datocms-structured-text-utils";
import { StructuredTextPropTypes } from "react-datocms";
import { variableTransformer } from "src/services/metadata/variable";

const renderText: StructuredTextPropTypes<Record>["renderText"] = (text) => {
  /**
   * Here we could alter the text content coming from DatoCMS if needed.
   */

  return variableTransformer(text);
};

export default renderText;

import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import { isStructuredText, Record } from "datocms-structured-text-utils";
import { FC } from "react";
import {
  StructuredText as DatoStructuredText,
  StructuredTextPropTypes,
} from "react-datocms";

import {
  renderBlock,
  renderInlineRecord,
  renderLinkToRecord,
  renderText,
} from "./lib";
import rules from "./rules";

type StructuredTextProps = {
  customRules?: StructuredTextPropTypes<Record>["customRules"];
  data: StructuredTextPropTypes<Record>["data"];
};

const StructuredText: FC<StructuredTextProps> = ({
  data,
  customRules = [],
}) => {
  const hasHubspotForms = !!data && isStructuredText(data) && data.blocks?.some(
    (block) => block.__typename === "DatoCmsHubspotForm"
  );

  const renderDatoStructuredText = () => (
    <DatoStructuredText
      customRules={[...customRules, ...rules]}
      data={data}
      renderBlock={renderBlock}
      renderInlineRecord={renderInlineRecord}
      renderLinkToRecord={renderLinkToRecord}
      renderText={renderText}
    />
  );
    
  if (hasHubspotForms) {
    return (
      <HubspotProvider>
        {renderDatoStructuredText()}
      </HubspotProvider>
    );
  }

  return renderDatoStructuredText();
};

export default StructuredText;

import { IDidomiObject } from "@didomi/react";
import { graphql, useStaticQuery } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { FC, useRef, useState } from "react";
import { Organization, Product, WebSite } from "schema-dts";
import { variableTransformer } from "src/services/metadata/variable";

import { getBreadcrumbListMarkup, getImageObjectMarkup, getPersonMarkup, getWebPageMarkup } from "./Markups";
import { BreadcrumbListMarkupType, GraphWithContext, ImageObjectMarkupType, PersonMarkupType, WebPageMarkupType } from "./Markups/types";

type TagType = {
  tagName: string;
  attributes?: Record<string, string>;
  content?: string;
};

const Seo: FC<{
  seo: Gatsby.GatsbyDatoCmsSeoMetaTagsFragment | null;
  path?: string;
  search?: string;
  breadcrumbMarkup?: BreadcrumbListMarkupType
  webPageMarkup?: WebPageMarkupType;
  imageObjectMarkup?: ImageObjectMarkupType | ImageObjectMarkupType[];
  personMarkup?: PersonMarkupType | PersonMarkupType[];
  hotjar?: boolean;
  children?: React.ReactNode;
}> = ({
  seo,
  path,
  search,
  hotjar,
  breadcrumbMarkup,
  webPageMarkup,
  imageObjectMarkup,
  personMarkup,
  children,
}) => {
  const didomiRef = useRef<null | IDidomiObject>(null);
  const { datoCmsSite, site, datoCmsFooter } = useStaticQuery<Gatsby.DatoCmsSiteQuery & Gatsby.DatoCmsFooter>(
    graphql`
      query DatoCmsSite {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }

        datoCmsSite {
          faviconMetaTags {
            tags
          }
        }

        datoCmsFooter {
          siteMarkup
          organizationMarkup
          logoMarkup
          productMarkup
        }
      }
    `
  );

  const [shouldAddHotjar, setShouldAddHotjar] = useState(false);

  const { title, description, siteUrl } = site!.siteMetadata!;

  let tags = seo!.tags as TagType[];

  /**
   * Search
   */
  if (path) {
    tags = tags.concat([
      {
        tagName: "link",
        attributes: {
          rel: "canonical",
          href: `${siteUrl}${path}${search ? search : ""}`,
        },
      },
    ]);
  }

  /**
   * Hotjar
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  if (hotjar && typeof window !== "undefined") {
    (window as any).didomiOnReady = (window as any).didomiOnReady || [];

    (window as any).didomiOnReady.push((didomi: IDidomiObject) => {
      didomiRef.current = didomi;

      if (didomi.getCurrentUserStatus().vendors.hotjar?.enabled && !shouldAddHotjar) {
        setShouldAddHotjar(true);
      }
    });

    (window as any).didomiEventListeners = (window as any).didomiEventListeners || [];

    (window as any).didomiEventListeners.push({
      event: 'consent.changed',
      listener: () => {
        const didomi = didomiRef.current;

        if (didomi === null) {
          return;
        }

        if (didomi.getCurrentUserStatus().vendors.hotjar?.enabled && !shouldAddHotjar) {
          setShouldAddHotjar(true);
        }
      }
    });
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  if (shouldAddHotjar) {
    tags = tags.concat([
      {
        tagName: "script",
        attributes: {
          type: "text/javascript",
        },
        content: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3881565,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
      },
    ]);
  }

  /**
   * Schema markups
   */
  const schemaMarkup: GraphWithContext = {
    "@context": "https://schema.org",
    "@graph": [],
  };

  if (datoCmsFooter?.siteMarkup) {
    try {
      const markup = JSON.parse(datoCmsFooter.siteMarkup) as WebSite;

      schemaMarkup["@graph"].push(markup);
    } catch (error) {
      // Do nothing
    }
  }

  if (datoCmsFooter?.organizationMarkup) {
    try {
      const markup = JSON.parse(datoCmsFooter.organizationMarkup) as Organization;

      schemaMarkup["@graph"].push(markup);
    } catch (error) {
      // Do nothing
    }
  }

  if (datoCmsFooter?.logoMarkup) {
    try {
      const markup = JSON.parse(datoCmsFooter.logoMarkup) as ImageObjectMarkupType;

      schemaMarkup["@graph"].push(getImageObjectMarkup(markup));
    } catch (error) {
      // Do nothing
    }
  }

  if (datoCmsFooter?.productMarkup) {
    try {
      const markup = JSON.parse(datoCmsFooter.productMarkup) as Product;

      schemaMarkup["@graph"].push(markup);
    } catch (error) {
      // Do nothing
    }
  }

  const pageMarkup: WebPageMarkupType = {
    url: `${siteUrl}${path}`,
    name: tags.find((tag) => tag.tagName === "title")?.content || title,
    description: tags.find((tag) => tag.attributes?.name === "description")?.attributes?.content || description,
  };

  if (breadcrumbMarkup) {
    const breadcrumbSchema = getBreadcrumbListMarkup(breadcrumbMarkup);

    schemaMarkup["@graph"].push(breadcrumbSchema);

    pageMarkup.breadcrumb = {
      "@type": "BreadcrumbList",
      "@id": breadcrumbSchema["@id"],
    };
  }

  if (imageObjectMarkup) {
    const imageObjectMarkups = (Array.isArray(imageObjectMarkup) ? imageObjectMarkup : [imageObjectMarkup])
      .map((markup) => getImageObjectMarkup(markup));
    
    schemaMarkup["@graph"].push(...imageObjectMarkups);

    pageMarkup.primaryImageOfPage = {
      '@id': imageObjectMarkups[0]?.['@id'] as string,
    };
  }

  if (personMarkup) {
    const personMarkups = (Array.isArray(personMarkup) ? personMarkup : [personMarkup])
      .map((markup) => getPersonMarkup(markup));

    schemaMarkup["@graph"].push(...personMarkups);

    pageMarkup.author = {
      '@id': personMarkups[0]?.['@id'] as string,
    };
  }

  schemaMarkup["@graph"].push(getWebPageMarkup({ ...pageMarkup, ...(webPageMarkup || {}) }));

  tags = tags.concat([
    {
      tagName: "script",
      attributes: {
        type: "application/ld+json",
      },
      content: JSON.stringify(schemaMarkup),
    }
  ]);

  /**
   * Variable transformation
   */
  if (tags) {
    tags.forEach((tag: TagType) => {
      if (tag.content) {
        tag.content = variableTransformer(tag.content);
      }

      if (tag.attributes?.content) {
        tag.attributes.content = variableTransformer(tag.attributes.content);
      }
    })
  }

  return (
    <HelmetDatoCms
      favicon={datoCmsSite?.faviconMetaTags}
      seo={{ ...seo, tags }}
    >
      {children}
    </HelmetDatoCms>
  );
};

export default Seo;

const clamp = (nb: number, min: number, max: number) => {
  return Math.max(Math.min(nb, max), min);
};

const createPageLink = (pathname: string, page: number, hash?: string) => {
  const pathnameWithTrailingSlash = pathname.endsWith('/') ? pathname : `${pathname}/`;

  const location = page <= 1 ? pathnameWithTrailingSlash : `${pathnameWithTrailingSlash}${page}/`;

  return hash ? `${location}#${hash}` : location;
};

/**
 * Based on where the user stands, displayed pages differ.
 * The displayed pages are always:
 * - First page
 * - Previous page (or two previous pages if the user is on the last page)
 * - Current page
 * - Next page (or two next pages if the user is on the first page)
 * - Last page
 *
 * E.g. `createPageRanges(1, 4)` => `[[1, 2, 3, 4]]`
 * E.g. `createPageRanges(20, 44)` => `[[1], [19, 20, 21], [44]]`
 */
const createPageRanges = (currentPage: number, total: number): number[][] => {
  const ranges: number[][] = [];
  let currentRange: number[] = [];
  let lastAddedPage = 0;

  const isCurrentPageExtremity = currentPage === 1 || currentPage === total;
  const distance = isCurrentPageExtremity ? 2 : 1;
  
  for (let p = 1 ; p <= total ; p++) {
    const isFirstPage = p === 1;
    const isBetweenPreviousAndNextPages = (currentPage - distance) <= p && p <= (currentPage + distance);
    const isLastPage = p === total;
    const shouldAddPage = isFirstPage || isBetweenPreviousAndNextPages || isLastPage;

    if (shouldAddPage) {
      if (p - 1 === lastAddedPage) {
        currentRange.push(p);
      } else {
        ranges.push(currentRange);
        currentRange = [p];
      }

      lastAddedPage = p;
    }
  }

  if (currentRange.length > 0) {
    ranges.push(currentRange);
  }

  return ranges;
};

export { clamp, createPageRanges, createPageLink };

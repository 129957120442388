import {
  Text,
  Box,
  Image,
  Stack,
  Heading,
  VStack,
  LinkBox,
  LinkOverlay,
  StackProps,
  Flex,
} from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import FormattedDates from "src/components/Core/ArticleCard/FormattedDates";
import { getCategoryLevel } from "src/services/article/category";
import { getFormattedDates } from "src/services/article/date";
import { getArticleLink } from "src/services/article/link";
import { getFormattedTitle } from "src/services/article/title";
import { getMultiDepthCategoryLink } from "src/services/categories/link";
import { variableTransformer } from "src/services/metadata/variable";


const FeaturedArticleCard: FC<Gatsby.DatoCmsArticleCardFragment & StackProps> =
  ({
    title,
    illustration,
    seo,
    slug,
    primaryCategory,
    date,
    meta,
    ...rest
  }) => {
    const upperCategories = primaryCategory
      ? getCategoryLevel(primaryCategory)
      : [];
    const formattedTitle = getFormattedTitle(variableTransformer(title!) || "");

    const { firstPublishedAt, updatedAt } = getFormattedDates(date!, meta);

    return (
      <LinkBox
        _hover={{
          ".image": {
            transform: "scale(1.02)",
          },
        }}
      >
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: 4, md: 10 }}
          {...rest}
        >
          {illustration && illustration.gatsbyImageData && (
            <Box
              borderRadius="2.5xl"
              maxWidth={{ lg: "calc(50% - 20px)" }}
              minWidth={{ lg: "calc(50% - 20px)" }}
              overflow="hidden"
              transform="translateZ(0)"
            >
              <Image
                alt={illustration?.alt || ""}
                as={GatsbyImage}
                h="full"
                image={illustration?.gatsbyImageData}
                imgClassName="image"
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1)",
                }}
                w="full"
              />
            </Box>
          )}

          <VStack align="start" spacing={{ base: 2, sm: 6 }}>
            <Flex
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-between"
              width="full"
              >
              {primaryCategory ? (
                <Text
                  color={{ base: "grey.500", lg: "grey.700" }}
                  fontSize="font-12"
                  letterSpacing="0.04em"
                  lineHeight="base"
                  textTransform="uppercase"
                >
                  <InternalLink
                    to={getMultiDepthCategoryLink(
                      primaryCategory.slug!,
                      upperCategories.slice(0, upperCategories.length - 1)
                    )}
                  >
                    {primaryCategory?.title}
                  </InternalLink>
                </Text>
              ) : null}

              <FormattedDates
                display={{ base: "flex", lg: "none" }}
                firstPublishedAt={firstPublishedAt}
                fontSize="font-11"
                iconScale={.8}
                lineHeight="22px"
                my="1"
                spacing={1}
                updatedAt={updatedAt}
                />
            </Flex>

            <Heading
              _hover={{ color: "text.secondary" }}
              as="h2"
              fontSize={{
                base: "font-22",
                md: "font-38",
              }}
              //line heights are specific to this heading only
              fontWeight={{ base: "semibold", md: "medium" }}
              lineHeight={{ base: 1.36, md: 1.25, lg: 1.21 }}
              transitionDuration="normal"
              transitionProperty="common"
              transitionTimingFunction="ease-out"
            >
              <LinkOverlay as={InternalLink} to={getArticleLink(slug!)}>
                {formattedTitle}
              </LinkOverlay>
            </Heading>

            <VStack
              align="flex-start"
              display={{ base: "none", lg: "block" }}
              spacing={0}
            >
              <Box mb={6}>
                <Text variant="article">
                  {variableTransformer(seo?.description || "")}
                </Text>
              </Box>

              <FormattedDates
                firstPublishedAt={firstPublishedAt}
                updatedAt={updatedAt}
                />
            </VStack>
          </VStack>
        </Stack>
      </LinkBox>
    );
  };

export default FeaturedArticleCard;

import {
  Box,
  Accordion,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  VStack,
  ButtonGroup,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { FC, Fragment } from "react";
import semanticColors from "src/@chakra-ui/gatsby-plugin/foundations/colors/semanticColors";
import { MotionBox } from "src/atoms";
import { CustomIcon } from "src/atoms";
import StructuredText from "src/components/StructuredText";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsFaqBlock: FC<Gatsby.DatoCmsFaqBlockFragment> = ({
  title,
  faqs,
}) => {
  return (
    <VStack
      align="start"
      itemScope
      itemType="https://schema.org/FAQPage"
      mx="auto"
      my={{ base: 6, lg: 20 }}
      spacing="6"
    >
      <Heading variant="heading-2">{title}</Heading>

      <Accordion allowMultiple allowToggle textAlign="left" width="100%">
        {faqs?.map((faq) => {
          if (!faq) return;

          return (
            <AccordionItem
              borderColor="grey.600"
              itemProp="mainEntity"
              itemScope
              itemType="https://schema.org/Question"
              key={faq.id}
            >
              {({ isExpanded }) => (
                <Fragment>
                  <ButtonGroup
                    sx={{
                      ".close-icon": {
                        path: {
                          fill: "text.primary",
                        },
                      },
                      ".open-icon": {
                        path: {
                          stroke: "grey.600",
                        },
                      },
                      ":hover": {
                        ".open-icon": {
                          path: {
                            stroke: "text.primary",
                          },
                        },
                      },
                    }}
                    w="full"
                  >
                    <AccordionButton
                      _focus={{
                        boxShadow: `0 0 0 1px ${semanticColors.text.primary}`,
                      }}
                      px="0"
                      py="6"
                    >
                      <Box flex="1" textAlign="left">
                        <Heading
                          _groupFocus={{ color: "text.primary" }}
                          _groupHover={{ color: "text.primary" }}
                          as="h3"
                          color={isExpanded ? "text.primary" : "grey.600"}
                          fontSize="font-20"
                          fontWeight="semibold"
                          itemProp="name"
                          lineHeight="shortest"
                          transitionDuration="fast"
                          transitionProperty="color"
                          transitionTimingFunction="ease-out"
                        >
                          {variableTransformer(faq.question!)}
                        </Heading>
                      </Box>

                      <Box h="6" ml={6} w="6">
                        <AnimatePresence>
                          <MotionBox
                            animate={{ opacity: 1, rotate: 0 }}
                            exit={{ display: "none" }}
                            initial={{ opacity: 0, rotate: 60 }}
                            key={isExpanded ? "closeBlueLg" : "plus"}
                          >
                            <CustomIcon
                              className={
                                isExpanded ? "close-icon" : "open-icon"
                              }
                              h="auto"
                              name={isExpanded ? "closeBlueLg" : "plus"}
                              w="auto"
                            />
                          </MotionBox>
                        </AnimatePresence>
                      </Box>
                    </AccordionButton>
                  </ButtonGroup>

                  <AccordionPanel
                    itemProp="acceptedAnswer"
                    itemScope
                    itemType="https://schema.org/Answer"
                    px="0"
                  >
                    <div itemProp="text">
                      <StructuredText data={faq.answer} />
                    </div>
                  </AccordionPanel>
                </Fragment>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </VStack>
  );
};

export default DatoCmsFaqBlock;

import { BoxProps, Box } from "@chakra-ui/react";
import { FC } from "react";
import { CustomArrowProps } from "react-slick";
import { CustomIcon } from "src/atoms";

const CustomArrow: FC<
  BoxProps &
    CustomArrowProps & { direction: "previous" | "next"; visible: boolean }
> = ({ onClick, direction, visible }) => {
  const isPrev = direction === "previous";

  return (
    <Box
      as="button"
      h="32px"
      left={isPrev ? "2" : "auto"}
      onClick={onClick}
      opacity={visible ? 1 : 0}
      position="absolute"
      right={isPrev ? "auto" : "2"}
      top="calc(50% - 16px)"
      transform={isPrev ? "rotate(-180deg)" : "none"}
      transitionDuration="fast"
      transitionProperty="common"
      transitionTimingFunction="ease-out"
      w="32px"
      zIndex="2"
    >
      <CustomIcon name="arrowCircleSm" />
    </Box>
  );
};

export default CustomArrow;

import { Heading } from "@chakra-ui/react";
import { renderRule, isHeading } from "datocms-structured-text-utils";

export const styles = {
  "1": {
    fontSize: "font-32",
    fontWeight: "500",
    lineHeight: "shortest",
    color: "text.primary",
    mt: 20
  },
  "2": {
    fontSize: "font-32",
    fontWeight: "500",
    lineHeight: "shortest",
    color: "text.primary",
    mt: 20
  },
  "3": {
    fontSize: "font-22",
    fontWeight: "600",
    lineHeight: "short",
    color: "text.primary",
  },
  "4": {
    fontSize: "font-20",
    fontWeight: "600",
    lineHeight: "shorter",
    color: "text.primary",
  },
  "5": undefined,
  "6": undefined,
} as const;

const heading = renderRule(isHeading, ({ node, children, key }) => {
  return (
    <Heading as={`h${node.level}`} key={key} mb="6" {...styles[node.level]}>
      {children}
    </Heading>
  );
});

export default heading;

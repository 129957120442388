import { Container, Box, Heading, Image } from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { renderRule, isHeading } from "datocms-structured-text-utils";
import { GatsbyImage } from "gatsby-plugin-image";
import { Fragment, createRef, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import Intro from "src/components/Article/Intro";
import MobileStickyCta from "src/components/Article/MobileStickyCta";
import RelatedArticles from "src/components/Article/RelatedArticles";
import MobileSummary from "src/components/Article/Summary/mobile";
import Breadcrumbs from "src/components/Core/Breadcrumbs";
import GlobalCta from "src/components/Core/GlobalCta";
import MostReadArticles from "src/components/Core/MostReadArticles";
import Seo from "src/components/Core/Seo";
import { ImageObjectMarkupType } from "src/components/Core/Seo/Markups/types";
import { breadcrumbMarkup, personMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import StructuredText from "src/components/StructuredText";
import { styles as headingStyles } from "src/components/StructuredText/rules/heading";
import scrollToSection from "src/lib/scrollToSection";
import throttle from "src/lib/throttle";
import { getBeenUpdated } from "src/services/article/date";
import { getSectionId } from "src/services/article/summary";
import { variableTransformer } from "src/services/metadata/variable";


type StructureType = {
  article: Gatsby.ArticleDataFragment | Gatsby.GuideDataFragment;
  globalCta: Gatsby.DatoCmsGlobalCtaFragment;
  relatedArticles:
    | readonly Gatsby.RelatedArticlesFragment[]
    | readonly Gatsby.RelatedGuidesFragment[];
  mostReadArticles: Gatsby.DatoCmsMostReadArticleFragment;
  mobileCta?: Gatsby.CtaStickyMobileFragment;
  current?: { title: string; link: string };
  crumbs?: { title: string; link: string }[];
  path: string;
};

const Structure = ({
  article,
  globalCta,
  relatedArticles,
  mostReadArticles,
  mobileCta,
  crumbs = [],
  current,
  path,
}: StructureType) => {
  const [activeSection, setActive] = useState<string | null>(null);
  const [activeMobileStickyCta, setActiveMobileStickyCta] = useState(false);

  const ref = createRef<HTMLDivElement>();

  const handleScroll = throttle(() => {
    setActiveMobileStickyCta(
      window.scrollY >= ((ref.current?.offsetTop ?? 0) - (ref.current?.scrollHeight ?? 0))
      && window.innerHeight + document.documentElement.scrollTop + 100 < document.documentElement.offsetHeight
    );
  }, 100);

  const handleActive = (inView: boolean, sectionId: string) => {
    if (inView) {
      setActive(sectionId);
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    
    if (hash) {
      setTimeout(() => scrollToSection(decodeURIComponent(hash).replace("#", "")), 500);
    }
  }, []);

  useEffect(() => {
    if (!mobileCta) {
      return undefined;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const { illustration, date, meta } = article;

  const imageObjectMarkup = [
    illustration ?
    {
      '@id': `${process.env.GATSBY_SHINE_URL}#/schema/article/image/`,
      url: illustration.url,
      contentUrl: illustration.url,
      width: illustration.width,
      height: illustration.height,
      caption: illustration.alt,
    } : null,
    article.author?.image ? {
      '@id': `${process.env.GATSBY_SHINE_URL}#/schema/person/image/`, 
      url: article.author.image.url,
      contentUrl: article.author.image.url,
      width: article.author.image.width,
      height: article.author.image.height,
      caption: article.author.image.alt,
    } : null,
  ].filter(Boolean) as ImageObjectMarkupType | ImageObjectMarkupType[];

  const beenUpdated = getBeenUpdated(date, meta);

  return (
    <Layout
      activeSection={activeSection!}
      article={article.body}
      articleCategorySlug={current?.link || ""}
      footerLinksBreakpoint="3xl"
    >
      <Seo
        breadcrumbMarkup={breadcrumbMarkup(crumbs, { name: current?.title || article.title!, path })}
        hotjar={article.hotjar}
        imageObjectMarkup={imageObjectMarkup}
        path={path}
        personMarkup={personMarkup(article.author!)}
        seo={article.seo!}
        webPageMarkup={{
          datePublished: article.meta!.firstPublishedAt,
          dateModified: beenUpdated ? article.meta!.updatedAt : article.meta!.firstPublishedAt,
        }}
      />

      <Box>
        <Container variant="block">
          <Container id="article-body" variant="narrow">
            <Breadcrumbs crumbs={crumbs} current={current} pl={0} pr={0} />

            <Intro {...article} />

            <MobileSummary data={article.body} />

            <Box mb={{ base: 10, md: 20 }} mt={10} ref={ref}>
              <Image
                alt={illustration?.alt || ""}
                as={GatsbyImage}
                image={illustration?.gatsbyImageData}
                imgStyle={{ borderRadius: "16px" }}
              />
            </Box>

            <StructuredText
              customRules={[
                renderRule(isHeading, ({ node, key }) => {
                  const plainText = variableTransformer(toPlainText(node) || '');
                  const sectionId = getSectionId(plainText);

                  return (
                    <Fragment key={key}>
                      <Heading
                        as={`h${node.level}`}
                        id={sectionId!}
                        mb="6"
                        {...headingStyles[node.level]}
                        position="relative"
                      >
                        {plainText}

                        {/* below box is to detect heading reached center of viewport */}

                        {node.level === 2 && (
                          <Box
                            pointerEvents="none"
                            position="absolute"
                            top="0"
                            w="100%"
                          >
                            <InView
                              onChange={(inView) =>
                                handleActive(inView, sectionId)
                              }
                              threshold={1}
                            >
                              {({ ref }) => <Box h="50vh" ref={ref} w="full" />}
                            </InView>
                          </Box>
                        )}
                      </Heading>
                    </Fragment>
                  );
                }),
              ]}
              data={article.body} //This TS error is benign and can be ignored for the moment
            />
          </Container>
        </Container>

        {globalCta ? <GlobalCta {...globalCta} isArticle /> : null}

        <RelatedArticles
          relatedArticles={relatedArticles}
          title={article.primaryCategory?.id === process.env.GATSBY_CATEGORY_NEWS ? `Nos dernières actualités` : undefined}
        />

        <MostReadArticles {...mostReadArticles!} isArticle />

        {mobileCta ? <MobileStickyCta active={activeMobileStickyCta} {...mobileCta} /> : null}
      </Box>
    </Layout>
  );
};

export default Structure;

import { Container, Heading } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Breadcrumbs from "src/components/Core/Breadcrumbs";
import GeneralGrid from "src/components/Core/GeneralGrid";
import GuideCard from "src/components/Core/GuideCard";
import PageBlock from "src/components/Core/PageBlock";
import Seo from "src/components/Core/Seo";
import { breadcrumbMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import CtaCard from "src/components/NosGuides/CtaCard";
import { getAllGuidesPageLink } from "src/services/guides/link";



const NosGuides: FC<PageProps<Gatsby.NosGuidesQuery>> = ({
  data: {
    datoCmsNosGuide,
    allDatoCmsGuide: { guides },
  },
}) => {
  if (!datoCmsNosGuide) return null;

  const { h1, blocks, seo, ctaCard } = datoCmsNosGuide;

  const list: (
    | Gatsby.DatoCmsGuideCardFragment
    | Gatsby.DatoCmsCtaCardFragment
  )[] = [...guides];

  if (ctaCard) {
    list.splice(7, 0, ctaCard!);
  }

  const link = getAllGuidesPageLink();

  return (
    <Layout>
      <Seo
        breadcrumbMarkup={breadcrumbMarkup([], { name: h1!, path: link })}
        path={link}
        seo={seo!}
      />

      <Breadcrumbs current={{ title: h1, link }} />

      <Container>
        <Container variant="block">
          <Heading as="h1" mb={{ base: 4, md: 10 }} variant="heading-1">
            {h1}
          </Heading>

          <GeneralGrid>
            {list.map((card) => {
              if (card.__typename === "DatoCmsGuide") {
                return <GuideCard {...card!} key={card.id} />;
              } else {
                return <CtaCard {...card} key={card.id} />;
              }
            })}
          </GeneralGrid>
        </Container>
      </Container>

      {blocks &&
        blocks.map((block) => {
          return <PageBlock {...block!} key={block?.id} />;
        })}
    </Layout>
  );
};

export default NosGuides;

export const query = graphql`
  query NosGuides {
    datoCmsNosGuide {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      h1
      ctaCard {
        ...DatoCmsCtaCard
      }
      blocks {
        ...DatoCmsMostReadArticlesBlock
        ...DatoCmsGlobalCtaBlock
        ...DatoCmsCollectionCarousel
        ...DatoCmsGuidesCarousel
      }
    }
    allDatoCmsGuide(sort: { fields: date, order: DESC }) {
      guides: nodes {
        ...DatoCmsGuideCard
      }
    }
  }
`;

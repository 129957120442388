import {
  Heading,
  Text,
  Box,
  Image,
  Flex,
  LinkBox,
  LinkOverlay,
  LinkBoxProps,
} from "@chakra-ui/react";
import { FC } from "react";

const ToolCard: FC<Gatsby.DatoCmsToolFragment & LinkBoxProps> = ({
  label,
  link,
  icon,
  summary,
  ...rest
}) => {
  return (
    <LinkBox data-component-name="LinkBox" {...rest}>
      {icon && (
        <Flex
          alignItems="center"
          bg="grey.200"
          borderRadius="2xl"
          className="tool-icon"
          justifyContent="center"
          mb={{ base: 2, md: 4 }}
          sx={{
            aspectRatio: "1",
            '[data-component-name="LinkBox"]:hover &': {
              backgroundColor: "grey.400",
            },
          }}
          transitionDuration="fast"
          transitionProperty="common"
          transitionTimingFunction="ease-out"
        >
          <Image alt={icon.alt || ""} src={icon.url} />
        </Flex>
      )}

      <Box>
        <Heading as="h3" variant="heading-3">
          <LinkOverlay href={link} isExternal>
            {label}
          </LinkOverlay>
        </Heading>

        <Text
          fontSize={{ base: "font-16", md: "font-19" }}
          lineHeight={{ base: "base", md: "tall" }}
          mt={{ base: 2 }}
          variant="article"
        >
          {summary}
        </Text>
      </Box>
    </LinkBox>
  );
};

export default ToolCard;

import { Box, VStack, Heading, Text } from "@chakra-ui/react";
import { FC } from "react";
import SocialShare from "src/components/Article/SocialShare";
import { HEADER_HEIGHT_DESKTOP } from "src/components/Layout/Header/Header";
import scrollToSection from "src/lib/scrollToSection";
import { getHeadings } from "src/services/article/summary";
import { variableTransformer } from "src/services/metadata/variable";

import { SummaryProps } from "../types";

const Summary: FC<SummaryProps> = ({ data, activeSection }) => {
  const headings: { heading: string; sectionId: string }[] = getHeadings(data);

  return (
    <Box
      borderLeft="1px"
      borderLeftColor={headings.length === 0 ? "transparent" : "grey.400"}
      h={`calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`}
      position="sticky"
      top={`${HEADER_HEIGHT_DESKTOP}px`}
    >
      <VStack
        mt="8"
        position="absolute"
        pr="6"
        spacing="2"
        transform="translateX(-100%)"
      >
        <SocialShare />
      </VStack>

      {headings.length > 0 && (
        <Box
          maxH={`calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`}
          overflowY="scroll"
          p="8"
          sx={{
            direction: "rtl",
            "::-webkit-scrollbar": {
              width: "1",
            },
            "::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "::-webkit-scrollbar-thumb": {
              background: "grey.400",
              borderRadius: "30px",
            },
          }}
          w="257px"
        >
          <Box sx={{ direction: "ltr" }}>
            <Heading
              as="div"
              fontWeight="semibold"
              lineHeight="taller"
              mb="6"
              variant="subtitle-medium"
            >
              Sommaire
            </Heading>
          </Box>

          <VStack align="left" pl="4" spacing="6" sx={{ direction: "ltr" }}>
            {headings.map(({ heading, sectionId }, index) => {
              const isActive =
                (!activeSection && index === 0) || activeSection === sectionId;

              return (
                <Box
                  key={sectionId}
                >
                  <Text
                    as="a"
                    borderBottom="1px"
                    borderBottomColor={isActive ? "grey.600" : "transparent"}
                    href={`#${sectionId}`}
                    lineHeight="taller"
                    onClick={(e) => scrollToSection(sectionId, e)}
                    pb={1}
                    variant="small"
                  >
                    {variableTransformer(heading)}
                  </Text>
                </Box>
              );
            })}
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default Summary;

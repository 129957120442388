import { Box, Text, Input, Button } from "@chakra-ui/react";
import React, { FC } from "react";
import { CustomIcon } from "src/atoms";

const EmailSubscribe: FC<Gatsby.EmailSubscribeFragment> = ({ copy }) => {
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    alert("email submit");
  };

  return (
    <Box bg="blue.300" borderRadius="2xl" px={4} py={4}>
      <Text lineHeight="short" mb={4} variant="subtitle-large">
        {copy}
      </Text>

      <form onSubmit={handleSubmit}>
        <Box position="relative">
          <Input
            _focus={{ outline: "none" }}
            _hover={{ outline: "none" }}
            bg="white"
            border="1px"
            borderColor="grey.700"
            borderRadius="full"
            h={12}
            outline="none"
            placeholder="jean@email.c.."
            pr={12}
            type="email"
          />

          <Button
            bg="grey.700"
            borderRadius="50%"
            h="12"
            position="absolute"
            right="0"
            top="calc(100% - 48px)"
            type="submit"
            variant="reset"
            w="12"
          >
            <CustomIcon name="arrow" />
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EmailSubscribe;

import { Image, Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import ArrowIcon from "src/images/ui/arrow-carousel.svg";

const CustomButton: FC<ButtonProps & { direction?: "next" }> = ({
  direction,
  ...rest
}) => {
  return (
    <Button
      _hover={{
        transform: "scale(1.1)",
      }}
      h="full"
      variant="reset"
      w={{ base: 8, md: 12 }}
      {...rest}
    >
      <Image
        alt="arrow"
        cursor="pointer"
        margin="0"
        src={ArrowIcon}
        transform={direction ? "rotate(-180deg)" : "rotate(0deg)"}
      />
    </Button>
  );
};

export default CustomButton;

import { Box, Flex, Container, ContainerProps } from "@chakra-ui/react";
import { useStaticQuery, graphql } from "gatsby";
import { FC } from "react";
import { ArrowButton, InternalLink } from "src/atoms";
import Carousel from "src/components/Core/Carousel";
import GuideCard from "src/components/Core/GuideCard";
import { getAllGuidesPageLink } from "src/services/guides/link"

const Guides: FC<ContainerProps> = ({ ...rest }) => {
  const { datoCmsGlobalGuidesList, allDatoCmsGuide } =
    useStaticQuery<Gatsby.DatoCmsGlobalGuidesListQuery>(
      graphql`
        query DatoCmsGlobalGuidesList {
          datoCmsGlobalGuidesList {
            title
            ctaLabel
          }
          allDatoCmsGuide {
            nodes {
              ...DatoCmsGuideCard
            }
          }
        }
      `
    );

  if (!datoCmsGlobalGuidesList) return null;

  const { ctaLabel, title } = datoCmsGlobalGuidesList;

  return (
    <Container overflow="visible" variant="block" {...rest}>
      <Carousel
        label={ctaLabel}
        length={allDatoCmsGuide.nodes?.length || 0}
        title={title}
        to={getAllGuidesPageLink()}
      >
        {allDatoCmsGuide.nodes?.map((guide) => {
          return (
            <Box
              flex="1"
              key={guide.id}
              maxW={{
                base: "214px",
                md: "260px",
              }}
            >
              <Flex boxSizing="border-box" h="full">
                <GuideCard
                  {...guide!}
                  maxW={{
                    base: "214px",
                    md: "260px",
                  }}
                />
              </Flex>
            </Box>
          );
        })}
      </Carousel>

      <Flex
        display={{ base: "flex", lg: "none" }}
        justifyContent="center"
        mt={{ base: 10, md: 9 }}
      >
        <ArrowButton as={InternalLink} to={getAllGuidesPageLink()}>
          {ctaLabel}
        </ArrowButton>
      </Flex>
    </Container>
  );
};

export default Guides;

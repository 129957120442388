import { Link } from "@chakra-ui/react";
import { defaultMetaTransformer as metaTransformer } from "datocms-structured-text-generic-html-renderer";
import { renderRule, isLink } from "datocms-structured-text-utils";
import { ReactElement } from "react";

type LinkProps = {
  children: ReactElement | null[];
  target?: string;
  href: string;
};

const StyledLink = ({ children, target, href }: LinkProps) => {
  let isExternal = false;

  if (target) {
    isExternal = target === "_blank" && true;
  }

  return (
    <Link
      _hover={{ textDecor: "none" }}
      color="text.cta"
      href={href}
      isExternal={isExternal}
    >
      {children}
    </Link>
  );
};

const link = renderRule(
  isLink,
  ({ adapter: { renderNode }, key, children, node }) => {
    const meta = node.meta ? metaTransformer({ node, meta: node.meta }) : {};

    return renderNode(
      StyledLink,
      { ...(meta || {}), key, href: node.url },
      children
    );
  }
);

export default link;

import {
  Box,
  Heading,
  Image,
  Text,
  Flex,
  Grid,
  Container,
  LinkBox,
  LinkOverlay,
  chakra,
  ContainerProps,
} from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import { getCategoryLevel } from "src/services/article/category";
import { getLatestFormattedDate } from "src/services/article/date";
import { getArticleLink } from "src/services/article/link";
import { getReadTime } from "src/services/article/readTime";
import { getFormattedTitle } from "src/services/article/title";
import { getMultiDepthCategoryLink } from "src/services/categories/link";
import { variableTransformer } from "src/services/metadata/variable";

const MostReadArticles: FC<
  Gatsby.DatoCmsMostReadArticleFragment &
    ContainerProps & { isArticle?: boolean }
> = ({ title, articles, listIcons, isArticle, ...rest }) => {
  const list: Array<{
    icon?: Gatsby.MostReadListIconFragment;
    article?: Gatsby.MostReadArticleFragment;
  }> = [];

  if (listIcons && articles) {
    articles.forEach((article, index) => {
      const icon = listIcons[index];

      if (icon) {
        list.push({ icon, article });
      }
    });
  }

  return (
    <Container variant="block" {...rest}>
      <Container>
        <Box
          borderBottom={{ md: "1px" }}
          borderBottomColor={{ md: "grey.400" }}
          borderTop="1px"
          borderTopColor="grey.400"
          pb={{ md: "20" }}
        >
          <Box w="full">
            <Heading
              fontWeight="medium"
              mb="10"
              mt={{ base: 10, md: 20 }}
              variant="heading-2"
            >
              {title}
            </Heading>
          </Box>

          <Grid
            gap={{ base: "8", md: "10" }}
            mt={"10"}
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
          >
            {list.map(({ article, icon }) => {
              if (!article || !icon) return null;

              const { title, slug, body, primaryCategory, date, meta } = article;

              const readTime = getReadTime(toPlainText(body) || "");

              const upperCategories = primaryCategory
                ? getCategoryLevel(primaryCategory)
                : [];

              const formattedTitle = getFormattedTitle(
                variableTransformer(title!) || ""
              );

              const displayedDate = getLatestFormattedDate(date!, meta);

              return (
                <LinkBox key={article.id}>
                  <Flex alignItems="flex-start">
                    <Image
                      alt={icon.alt || ""}
                      h={{ base: "auto", md: "56px" }}
                      mr="2"
                      src={icon.url}
                      w="40px"
                    />

                    <Box>
                      <Text
                        color="text.secondary"
                        display={{ base: "block", md: "none" }}
                        fontSize="font-11"
                        letterSpacing="0.04em"
                        lineHeight="shortest"
                        mb="2"
                        minH={{ base: "unset", md: "28px", "2xl": "unset" }}
                        textTransform="uppercase"
                      >
                        {primaryCategory && (
                          <Box
                            as={InternalLink}
                            pr="3"
                            to={getMultiDepthCategoryLink(
                              primaryCategory.slug!,
                              upperCategories.slice(
                                0,
                                upperCategories.length - 1
                              )
                            )}
                          >
                            {primaryCategory.title}
                          </Box>
                        )}

                        <chakra.span
                          color="text.primary"
                          display={{ base: "inline", md: "none" }}
                          fontSize="font-11"
                          letterSpacing="0.04em"
                          lineHeight="shortest"
                          textTransform="none"
                        >
                          {displayedDate}
                        </chakra.span>
                      </Text>

                      {readTime ? <Text
                        display={{ base: "none", md: "block" }}
                        fontSize={{ base: "font-11", md: "font-12" }}
                        mb="2"
                      >
                        {readTime}
                      </Text> : null}

                      <Heading
                        _hover={{ color: "text.secondary" }}
                        as="h3"
                        transitionDuration="fast"
                        transitionProperty="common"
                        transitionTimingFunction="ease-out"
                        variant="heading-3"
                      >
                        <LinkOverlay
                          as={InternalLink}
                          to={getArticleLink(slug || "")}
                        >
                          {formattedTitle}
                        </LinkOverlay>
                      </Heading>
                    </Box>
                  </Flex>
                </LinkBox>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Container>
  );
};

export default MostReadArticles;

import { Box } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { Fragment } from "react";
import SearchInput from "src/atoms/SearchInput";

import EmailSubscribe from "./parts/EmailSubscribe";
import Section from "./parts/Section";

const SideBar = ({ articleCategorySlug }: { articleCategorySlug?: string }) => {
  const { navigation } = useStaticQuery<Gatsby.NavigationQuery>(
    graphql`
      query Navigation {
        navigation: datoCmsNavigation {
          ...DatoCmsNavigation
        }
      }
    `
  );

  const { navigationBlocks, emailSubscribe } = navigation!;

  return (
    <Box as="nav" height="max" p="6" position="sticky" top={20} w="full">
      <SearchInput mb={10} placeholder="Rechercher..." />

      {navigationBlocks?.map((section) => {
        return (
          <Fragment key={section?.id}>
            <Section {...section!} articleCategorySlug={articleCategorySlug} />
          </Fragment>
        );
      })}

      {emailSubscribe && <EmailSubscribe {...emailSubscribe} />}
    </Box>
  );
};

export default SideBar;

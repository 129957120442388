import { Text, Box, Flex, Heading, Image, chakra, HStack } from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
  import { FC } from "react";
import { InternalLink } from "src/atoms";
import { getBeenUpdated } from "src/services/article/date";
import { getReadTime } from "src/services/article/readTime";
import { getAuthorLink } from "src/services/author/link";
import { variableTransformer } from "src/services/metadata/variable";

import StructuredText from "../../StructuredText";
import SocialShare from "../SocialShare";

const Intro: FC<Gatsby.ArticleDataFragment | Gatsby.GuideDataFragment> = ({
  author,
  title,
  intro,
  body,
  meta,
  date,
}) => {
  const readTime = getReadTime(toPlainText(body) || "");

  const beenUpdated = getBeenUpdated(date, meta);

  return (
    <Box mb="10">
      <Heading
        as="h1"
        fontSize={{ base: "font-32", md: "font-40" }}
        lineHeight="shortest"
      >
        {variableTransformer(title!)}
      </Heading>

      <Flex alignItems="center" flexWrap="wrap" mb={{ base: 6, xl: 10 }} mt={6}>
        <Text
          as="div"
          borderRightColor="grey.400"
          color="text.secondary"
          lineHeight="tall"
          mr={2}
          variant="small"
        >
          {author && (
            <>
              {author.image && (
                <Image
                  alt={author.image.alt || ""}
                  as={GatsbyImage}
                  height="6"
                  image={author.image.gatsbyImageData}
                  imgStyle={{
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                  mr="2.5"
                  overflow="hidden"
                  width="6"
                />
              )}

              <chakra.span
                borderRight={"1px"}
                borderRightColor="grey.400"
                mr="2"
                pr="2"
              >
                <InternalLink to={getAuthorLink(author.slug!)}>
                  par {author.name}
                </InternalLink>
              </chakra.span>
            </>
          )}

          {meta?.formattedFirstPublishedAt ? (
            <chakra.span
              borderRight={"1px"}
              borderRightColor="grey.400"
              mr="2"
              pr="2"
            >
              {" "}

              Publié le {meta.formattedFirstPublishedAt}
            </chakra.span>
          ) : null}

          {(beenUpdated && meta) ? (
            <chakra.span
              borderRight={"1px"}
              borderRightColor="grey.400"
              mr="2"
              pr="2"
            >
              Mis à jour le {meta.formattedUpdatedAt}
            </chakra.span>
          ) : null}

          <chakra.span>{readTime}</chakra.span>
        </Text>
      </Flex>

      <HStack
        display={{ base: 'flex', xl: 'none' }}
        spacing="2"
        >
        <SocialShare tooltipPlacement="top" />
      </HStack>

      {intro && intro.value !== null && (
        <Box
          borderLeft="2px"
          borderLeftColor="orange.500"
          my={14}
          paddingLeft="6"
        >
          <StructuredText data={intro} />
        </Box>
      )}
    </Box>
  );
};

export default Intro;

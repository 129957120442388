import { Box, Heading, Container, BoxProps } from "@chakra-ui/react";
import { FC } from "react";
import ArticleCard from "src/components/Core/ArticleCard";
import ArticleGrid from "src/components/Core/ArticleGrid";
import FeaturedArticle from "src/components/Core/FeaturedArticle";

const SelectedArticles: FC<
  BoxProps & {
    articles: readonly Gatsby.Maybe<Gatsby.DatoCmsArticleCardFragment>[];
  }
> = ({ articles, ...rest }) => {
  if (!articles || articles.length === 0) return null;

  const mostRecentArticle = articles[0]!;
  const articlesList = articles.slice(1, articles.length);

  return (
    <Box {...rest} mb={{ base: 20, md: 32 }}>
      <Container>
        <Heading
          as="h2"
          display={{ base: "none", md: "block"}}
          fontSize={{ base: "font-28", md: "font-26" }}
          fontWeight="medium"
          mb={{ base: 6, md: 10 }}
          variant="heading-2"
        >
          Nos articles préférés
        </Heading>

        <FeaturedArticle {...mostRecentArticle} />

        <ArticleGrid columns={{ base: 1, md: 3 }} mt={{ base: 6, md: 10 }}>
          {articlesList.map((article) => {
            if (!article) return null;

            return <ArticleCard {...article} key={article.id!} />;
          })}
        </ArticleGrid>
      </Container>
    </Box>
  );
};

export default SelectedArticles;

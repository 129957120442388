import { Box, Text, Heading } from "@chakra-ui/react";
import { isParagraph, renderRule } from "datocms-structured-text-utils";
import { FC } from "react";
import StructuredText from "src/components/StructuredText";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsRecap: FC<Gatsby.DatoCmsRecapFragment> = ({ body, title }) => (
  <Box
    bg="grey.200"
    borderRadius="2xl"
    my={{ base: 6, lg: 20 }}
    padding={{ base: 6, lg: 20 }}
  >
    <Heading
      as="h3"
      color="text.secondary"
      fontSize="font-20"
      lineHeight="short"
      mb="2.5"
      variant="heading-3"
    >
      {variableTransformer(title!)}
    </Heading>

    <StructuredText
      customRules={[
        renderRule(isParagraph, ({ children, key }) => (
          <Text key={key} variant="article">
            {children}
          </Text>
        )),
      ]}
      data={body}
    />
  </Box>
);

export default DatoCmsRecap;

import {
  Box,
  Text,
  Heading,
  Container,
  BoxProps,
  Stack,
} from "@chakra-ui/react";
import { FC, Fragment } from "react";

import Desktop from "./SubCategories/Desktop";
import Mobile from "./SubCategories/Mobile";

const Intro: FC<
  BoxProps & {
    intro: string;
    title: string;
    subCategories: { title: string; id: string; link: string }[];
  }
> = ({ intro, title, subCategories, ...rest }) => {
  return (
    <Stack mb={{ base: 6, md: 18 }} {...rest} >
      <Box order={{ base: 1, md: 0 }}>
        <Container>
          <Heading
            as="h1"
            fontSize={{ base: "font-28", md: "font-32" }}
            mb={{ base: 0, md: 6 }}
          >
            {title}
          </Heading>

          <Text display={{ base: "none", md: "block" }}>{intro}</Text>
        </Container>
      </Box>

      <Box order={{ base: 0, md: 1 }} pb={{ base: 10, md: 0 }}>
        {subCategories && subCategories.length > 0 && (
          <Fragment>
            <Mobile subCategories={subCategories} />

            <Container>
              <Desktop subCategories={subCategories} />
            </Container>
          </Fragment>
        )}
      </Box>
    </Stack>
  );
};

export default Intro;

import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { FC, Fragment } from "react";

import CustomArrow from "./CustomArrow";
import PaginateButton from "./PaginateButton";
import { clamp, createPageLink, createPageRanges } from "./utils";

const Pagination: FC<
  BoxProps & {
    total?: number;
    current?: number;
    link?: string;
    hash?: string;
  }
> = ({ current, total, link, hash }) => {
  if (!link || !total || !current) return null;

  const changePagination = (newPage: number) => {
    const newPageLink = createPageLink(link, newPage, hash);

    navigate(newPageLink);
  };

  const visiblePageRanges: number[][] = createPageRanges(current, total);
  const previousPageIndex = clamp(current - 1, 1, total);
  const nextPageIndex = clamp(current + 1, 1, total);
  const lastPageRandeIndex = visiblePageRanges.length - 1;

  return (
    <Box mt={{ base: 8, md: 20 }}>
      <Box>
        <HStack justifyContent="center" spacing={2}>
          <CustomArrow
            direction="previous"
            disabled={current === 1}
            onClick={() => changePagination(previousPageIndex)}
          />

          {visiblePageRanges.map((pageRange, index) => {
            const isLastPageRange = index === lastPageRandeIndex;

            return (
              <Fragment key={`page-range-${index}`}>{pageRange.map((page) => {
                const pageLink = createPageLink(link, page, hash);
                const isCurrent = page === current;

                return <PaginateButton
                  isCurrent={isCurrent}
                  key={page}
                  link={pageLink}
                  page={page}
                />
                })}

                {isLastPageRange ?
                  null : <Box>...</Box>
                }
              </Fragment>
            );
          })}

          <CustomArrow
            direction="next"
            disabled={current === total}
            onClick={() => changePagination(nextPageIndex)}
          />
        </HStack>
      </Box>
    </Box>
  );
};

export default Pagination;

import { Container, Heading } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { InView } from "react-intersection-observer";
import ArticleCard from "src/components/Core/ArticleCard";
import ArticleGrid from "src/components/Core/ArticleGrid";
import Pagination from "src/components/Core/Pagination";
import scrollToSection from "src/lib/scrollToSection";

type ArticleCardType = {
  articles: Gatsby.DatoCmsArticleCardFragment[];
  totalPages?: number;
  pageIndex?: number;
  link?: string;
};

const ArticleList: FC<ArticleCardType> = ({ articles, totalPages, pageIndex, link }) => {
  const sectionId = "du-meme-auteur"

  const onViewExit = (inView: boolean) => {
    if (!inView) {
      window.history.replaceState(null, '', ' ');
    }
  }

  useEffect(() => {
    const hash = window.location.hash;
    
    if (hash) {
      setTimeout(() => scrollToSection(decodeURIComponent(hash).replace("#", ""), undefined, "start"), 500);
    }
  }, []);

  return (
    <InView onChange={onViewExit} threshold={1}>
      <Container id={sectionId} mb={{ base: 10, md: 30 }}>
        <Heading
          as="h2"
          fontWeight="medium"
          lineHeight={{ base: "shortest", md: "shorter" }}
          mb={{ base: 6, md: 10 }}
          variant="heading-2"
        >
          Du même auteur
        </Heading>

        <ArticleGrid columns={{ base: 1, md: 3, xl: 4 }}>
          {articles.map((card) => <ArticleCard {...card!} key={card.id} />)}
        </ArticleGrid>

        {totalPages && totalPages > 1 ? (
          <Pagination current={pageIndex} hash={sectionId} link={link} total={totalPages} />
        ) : null}
      </Container>
    </InView>
  );
}

export default ArticleList;

import { PageProps, graphql } from "gatsby";
import { FC } from "react";
import Author from "src/components/Author";

import { AuthorPageContextType } from "./types";

const AuthorTemplate: FC<PageProps<Gatsby.AuthorQuery> & AuthorPageContextType> = ({
  pageContext: { pageIndex, totalPages, pagePath },
  data: { datoCmsAuthor, firstArticle, latestArticles },
}) => {
  if (!datoCmsAuthor) return null;

  return (
    <Author
      author={datoCmsAuthor}
      firstArticle={firstArticle.nodes}
      latestArticles={latestArticles.nodes}
      pageIndex={pageIndex}
      path={pagePath}
      seo={datoCmsAuthor.seo}
      totalPages={totalPages}
    />
  );
}

export default AuthorTemplate;

export const query = graphql`
  query Author($id: String, $limit: Int!, $skip: Int!) {
    datoCmsAuthor(originalId: { eq: $id }) {
      ...AuthorData
    }
    firstArticle: allDatoCmsArticle(
      filter: { author: { originalId: { eq: $id } } }
      sort: { fields: meta___firstPublishedAt, order: ASC }
      limit: 1
      skip: 0
    ) {
      nodes {
        id
        title
        slug
        meta {
          firstPublishedAt(formatString: "DD MMM YYYY", locale: "fr")
        }
      }
    }
    latestArticles: allDatoCmsArticle(
      filter: { author: { originalId: { eq: $id } } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: $limit,
      skip: $skip
    ) {
      nodes {
        ...DatoCmsArticleCard
      }
    }
  }
`;

import getHeadings from "./getHeadings";

type Heading = { heading: string; sectionId: string };

export default function getHasSidebar(
  data: Gatsby.ArticleDataFragment["body"]
): boolean {
  let headings: Heading[] = [];

  if (data) {
    headings = getHeadings(data!)
  }

  return headings.length > 0 ? true : false;
}

import {
  Text,
  Box,
  Grid,
  Container,
  BoxProps,
  Image,
  Link,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import * as DOMPurify from 'isomorphic-dompurify';
import { FC, Fragment } from "react";
import breakpoints from "src/@chakra-ui/gatsby-plugin/foundations/breakpoints";
import { InternalLink } from "src/atoms";
import AppStoreImage from "src/images/app-store.png";
import GooglePlayImage from "src/images/google-play.png";
import ShineLogo from "src/images/shine.svg";

import Ratings from "./parts/Ratings";
import SocialMedia from "./parts/SocialMedia";
import Tab from "./parts/Tab";

type FooterProps = BoxProps & {
  linksBreakpoint?: keyof typeof breakpoints;
};

const Footer: FC<FooterProps> = ({
  linksBreakpoint = "xl",
  ...rest
}) => {
  const { footer } = useStaticQuery<Gatsby.FooterQuery>(
    graphql`
      query Footer {
        footer: datoCmsFooter {
          ...DatoCmsFooter
        }
      }
    `
  );

  const { footerLinks, disclaimerNode } = footer!;

  const canSanitize = DOMPurify.isSupported;
  const disclaimerUnsanitized = disclaimerNode?.childMarkdownRemark?.html || '';
  const disclaimerPlainText = disclaimerNode?.childMarkdownRemark?.excerpt || '';
  const disclaimerSanitized = DOMPurify.sanitize(disclaimerUnsanitized, { USE_PROFILES: { html: true } });

  const legalLinks = {
    id: 'legal-links',
    title: 'Legal Links',
    links: [
      {
        label: `Conditions d’utilisation`,
        link: 'https://www.shine.fr/lp/cgu-ep-en-clair/',
      },
      {
        label: `Mentions légales`,
        link: 'https://www.shine.fr/mentions-legales/',
      },
    ],
  }

  return (
    <Box as="footer" {...rest}>
      <Container>
        <Box
          borderTop="1px"
          borderTopColor={{ base: "grey.400", md: "transparent" }}
          pb={{ base: 4, md: 16, lg: 20 }}
          pt={{ base: 20, lg: 0 }}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            gridGap={{ base: 20, md: 10, content: 20 }}
            justifyContent="space-between"
          >
            <Flex
              alignItems={{ base: "center", lg: "flex-start" }}
              direction="column"
              justifyContent="flex-start"
              minW="322px"
              >
              <Image height="6" src={ShineLogo} />

              <Text
                as="p"
                fontSize="font-20"
                fontWeight="semibold"
                lineHeight="shortest"
                mb={{ base: 10, lg: 4 }}
                mt="4"
                variant="heading-3"
              >
                Le compte pro qui en fait plus
              </Text>

              <HStack spacing="3">
                <Link as="a" href="https://apps.apple.com/fr/app/shine-compte-pro-en-ligne/id1159779855" isExternal target="_blank">
                  <Image height="10" src={AppStoreImage} />
                </Link>

                <Link as="a" href="https://play.google.com/store/apps/details?id=com.shine.app&hl=fr&gl=US" isExternal target="_blank">
                  <Image height="10" src={GooglePlayImage} />
                </Link>
              </HStack>

              <Box as="div" flex={{ base: 0, xl: 1 }} />

              <Ratings {...footer!} />

              <Box display={{ base: "block", lg: "none" }}>
                <SocialMedia {...footer!} />
              </Box>
            </Flex>

            <Box>
              <Grid
                gap={{ base: "6", lg: "10" }}
                gridTemplateColumns={{
                  base: "repeat(2, 1fr)",
                  [linksBreakpoint]: "repeat(4, 1fr)",
                }}
                w="100%"
              >
                {footerLinks?.map((tab) => {
                  return (
                    <Fragment key={tab?.id}>
                      <Tab {...tab!} />
                    </Fragment>
                  );
                })}
              </Grid>

              <Box display={{ base: "none", lg: "block" }}>
                <SocialMedia {...footer!} />
              </Box>
            </Box>
          </Flex>

          <Box mt={{ base: "8", lg: "20" }}>
            {canSanitize ? <Box
              dangerouslySetInnerHTML={{ __html: disclaimerSanitized }}
              fontSize="font-11"
              letterSpacing="04"
              sx={{
                a: {
                  textDecoration: "underline",
                },
              }}
            /> : <Text
              fontSize="font-11"
              letterSpacing="04"
              sx={{
                a: {
                  textDecoration: "underline",
                },
              }}>
                {disclaimerPlainText}
              </Text>
            }
          </Box>

          <Flex
            alignItems="center"
            gridGap="4"
            justifyContent="center"
            mt="8"
          >
            {legalLinks.links.map((link) => {
              if (!link) return null;

              const isInternal = link.link?.startsWith("/");

              return (
                <Text
                  _hover={{ color: "text.secondary" }}
                  fontSize="font-14"
                  key={link.label}
                  lineHeight="tallest"
                  textAlign="center"
                >
                  <Link
                    as={isInternal ? InternalLink : undefined}
                    href={link.link!}
                    to={link.link!}
                  >
                    {link.label}
                  </Link>
                </Text>
              )
            })}
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nos-guides-tsx": () => import("./../../../src/pages/nos-guides.tsx" /* webpackChunkName: "component---src-pages-nos-guides-tsx" */),
  "component---src-pages-result-tsx": () => import("./../../../src/pages/result.tsx" /* webpackChunkName: "component---src-pages-result-tsx" */),
  "component---src-templates-article-article-tsx": () => import("./../../../src/templates/Article/Article.tsx" /* webpackChunkName: "component---src-templates-article-article-tsx" */),
  "component---src-templates-author-author-tsx": () => import("./../../../src/templates/Author/Author.tsx" /* webpackChunkName: "component---src-templates-author-author-tsx" */),
  "component---src-templates-collection-collection-tsx": () => import("./../../../src/templates/Collection/Collection.tsx" /* webpackChunkName: "component---src-templates-collection-collection-tsx" */),
  "component---src-templates-multi-depth-category-levels-fifth-tsx": () => import("./../../../src/templates/MultiDepthCategory/levels/fifth.tsx" /* webpackChunkName: "component---src-templates-multi-depth-category-levels-fifth-tsx" */),
  "component---src-templates-multi-depth-category-levels-first-tsx": () => import("./../../../src/templates/MultiDepthCategory/levels/first.tsx" /* webpackChunkName: "component---src-templates-multi-depth-category-levels-first-tsx" */),
  "component---src-templates-multi-depth-category-levels-fourth-tsx": () => import("./../../../src/templates/MultiDepthCategory/levels/fourth.tsx" /* webpackChunkName: "component---src-templates-multi-depth-category-levels-fourth-tsx" */),
  "component---src-templates-multi-depth-category-levels-second-tsx": () => import("./../../../src/templates/MultiDepthCategory/levels/second.tsx" /* webpackChunkName: "component---src-templates-multi-depth-category-levels-second-tsx" */),
  "component---src-templates-multi-depth-category-levels-third-tsx": () => import("./../../../src/templates/MultiDepthCategory/levels/third.tsx" /* webpackChunkName: "component---src-templates-multi-depth-category-levels-third-tsx" */),
  "component---src-templates-single-collection-page-collection-page-tsx": () => import("./../../../src/templates/single/CollectionPage/CollectionPage.tsx" /* webpackChunkName: "component---src-templates-single-collection-page-collection-page-tsx" */)
}


import { Box, Text, Stack } from "@chakra-ui/react";
import { isParagraph, renderRule } from "datocms-structured-text-utils";
import { FC } from "react";
import StructuredText from "src/components/StructuredText";

const DatoCmsBlockQuoteEmoji: FC<Gatsby.DatoCmsBlockQuoteEmojiFragment> = ({
  emoji,
  content,
}) => {
  return (
    <Stack
      align="start"
      border="1px"
      borderColor="green.600"
      borderRadius="2xl"
      direction={{base: "column", md: "row"}}
      my={{ base: 6, lg: 10 }}
      p={{ base: 6, md: 10 }}
      spacing="4"
    >
      <Box>
        <Text fontSize="30px" lineHeight="short">
          {emoji}
        </Text>
      </Box>

      <Box>
        <StructuredText
          customRules={[
            renderRule(isParagraph, ({ children, key }) => (
              <Text key={key} variant="article">
                {children}
              </Text>
            )),
          ]}
          data={content}
        />
      </Box>
    </Stack>
  );
};

export default DatoCmsBlockQuoteEmoji;

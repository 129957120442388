import { Box, Code } from "@chakra-ui/react";

const printPlaceholder = (input: unknown) => {
  if (typeof input === "object") return JSON.stringify(input, null, 2);
  if (["number", "string"].includes(typeof input)) return input;

  return null;
};

/**
 * A dev-only component to display any content during development.
 * Useful for CMS-incoming data for which we have not yet created a renderer.
 */
const Placeholder = ({
  title,
  children,
}: {
  title?: string;
  children: unknown;
}) => (
  <Box my="4">
    {title && (
      <Code bg="gray.300" display="block" fontSize="sm" fontWeight="bold">
        {title}
      </Code>
    )}

    <Code
      as="pre"
      bg="gray.200"
      display="block"
      fontSize="11px"
      p="2"
      whiteSpace="pre-wrap"
    >
      {printPlaceholder(children)}
    </Code>
  </Box>
);

export default Placeholder;

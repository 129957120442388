import { Container } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import { FC, Fragment } from "react";
import Intro from "src/components/Collection/Intro";
import ArticleCard from "src/components/Core/ArticleCard";
import ArticleGrid from "src/components/Core/ArticleGrid";
import CtaCard from "src/components/Core/ArticleGrid/CtaCard";
import Breadcrumbs from "src/components/Core/Breadcrumbs";
import PageBlock from "src/components/Core/PageBlock";
import Seo from "src/components/Core/Seo";
import { breadcrumbMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import { getCollectionLink } from "src/services/collection/link";

const Collection: FC<PageProps<Gatsby.CollectionQuery>> = ({
  data: { datoCmsCollection },
}) => {
  if (!datoCmsCollection) return null;

  const { slug, name, blocks, seo, articleList } = datoCmsCollection;
  const link = getCollectionLink(slug!);

  return (
    <Layout>
      <Seo
        breadcrumbMarkup={breadcrumbMarkup([], { name: name!, path: link })}
        path={link}
        seo={seo!}
      />

      <Breadcrumbs current={{ title: name, link }} />

      <Intro {...datoCmsCollection} />

      <Container variant="block">
        <Container>
          <ArticleGrid mt={{ base: 6, md: 10 }}>
            {articleList?.map((card) => {
              if (!card) return null;

              return (
                <Fragment key={card.id}>
                  {card.__typename === "DatoCmsArticle" ? (
                    <ArticleCard {...card} />
                  ) : (
                    <CtaCard {...card} />
                  )}
                </Fragment>
              );
            })}
          </ArticleGrid>
        </Container>
      </Container>

      {blocks &&
        blocks.map((block) => {
          return <PageBlock {...block!} key={block?.id} />;
        })}
    </Layout>
  );
};

export default Collection;

export const query = graphql`
  query Collection($id: String) {
    datoCmsCollection(id: { eq: $id }) {
      ...CollectionData
    }
  }
`;

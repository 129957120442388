import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Category from "src/components/Category";

import { CategoryPageContextType } from "./types";

const FourthCategory: FC<
  PageProps<Gatsby.FourthCategoryQuery> & CategoryPageContextType
> = ({
  pageContext: { pageIndex, upperCategories, subCategories, totalPages, pagePath },
  data: { datoCmsFourthCategory, allDatoCmsArticle },
}) => {
  if (!datoCmsFourthCategory || !allDatoCmsArticle) return null;

  return (
    <Category
      articles={[...allDatoCmsArticle.nodes]}
      category={datoCmsFourthCategory}
      categoryTitle={datoCmsFourthCategory.title!}
      pageIndex={pageIndex!}
      path={pagePath!}
      seo={datoCmsFourthCategory.seo!}
      subCategories={subCategories!}
      totalPages={totalPages!}
      upperCategories={upperCategories!}
    />
  );
};

export default FourthCategory;

export const query = graphql`
  query FourthCategory($id: String, $currentCategoryArticles: [String!], $skip: Int!, $limit: Int!) {
    datoCmsFourthCategory(id: { eq: $id }) {
      ...FourthCategoryData
    }
    allDatoCmsArticle(
      filter: { id: { in: $currentCategoryArticles } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...DatoCmsArticleCard
      }
    }
  }
`;

/**
 * @see https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9
 */
import "focus-visible/dist/focus-visible";

import { GatsbyBrowser } from "gatsby";
import Cookies from "src/components/Cookies";
import "./main.css";
import { hasChangedLocation } from "src/lib/event";
import { page } from "src/lib/analytics";
import { getPageSubCatObj } from "src/lib/event/properties";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => <Cookies>{element}</Cookies>;

export const onClientEntry: GatsbyBrowser["onClientEntry"] = async () => {};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  location,
  prevLocation,
}) => {
  const shouldLogPageView = hasChangedLocation(prevLocation, location);

  if (shouldLogPageView) {
    const properties = {
      pageEnvironment: process.env.GATSBY_DEPLOY_ENV,
      pageTemplate: 'Blog',
      pageSite: 'blog',
      ...getPageSubCatObj(location)
    };

    page('', document.title, properties);
  }
};

export const disableCorePrefetching: GatsbyBrowser["disableCorePrefetching"] = () => true;

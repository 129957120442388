import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { FC } from "react";

const GeneralGrid: FC<SimpleGridProps> = ({ children, ...rest }) => {
  return (
    <SimpleGrid
      autoRows="1fr"
      columns={{ base: 2, md: 3, xl: 4 }}
      gap={{ base: 6, md: 8 }}
      mx="auto"
      {...rest}
    >
      {children}
    </SimpleGrid>
  );
};

export default GeneralGrid;

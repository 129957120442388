import { Divider } from "@chakra-ui/react";
import { renderRule, isThematicBreak } from "datocms-structured-text-utils";

const StyledDivider = () => {
  return (
    <Divider
      _before={{
        textAlign: "center",
        content: "'...'",
        display: "block",
        fontSize: "30px",
        lineHeight: 0.5,
        letterSpacing: ".6em",
        color: "text-secondary",
      }}
      borderColor="transparent"
      height="30px"
      marginY="4"
      orientation="horizontal"
    />
  );
};

const thematicBreak = renderRule(
  isThematicBreak,
  ({ adapter: { renderNode }, children }) => {
    return renderNode(StyledDivider, children);
  }
);

export default thematicBreak;

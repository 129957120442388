import { graphql, PageProps } from "gatsby";
import { FC } from "react";
import Category from "src/components/Category";

import { CategoryPageContextType } from "./types";

const SecondCategory: FC<
  PageProps<Gatsby.SecondCategoryQuery> & CategoryPageContextType
> = ({
  pageContext: { pageIndex, upperCategories, subCategories, totalPages, pagePath },
  data: { datoCmsSubCategory, allDatoCmsArticle },
}) => {
  if (!datoCmsSubCategory || !allDatoCmsArticle) return null;

  return (
    <Category
      articles={[...allDatoCmsArticle.nodes]}
      category={datoCmsSubCategory}
      categoryTitle={datoCmsSubCategory.title!}
      pageIndex={pageIndex!}
      path={pagePath!}
      seo={datoCmsSubCategory.seo!}
      subCategories={subCategories!}
      totalPages={totalPages!}
      upperCategories={upperCategories!}
    />
  );
};

export default SecondCategory;

export const query = graphql`
  query SecondCategory($id: String, $currentCategoryArticles: [String!], $skip: Int!, $limit: Int!) {
    datoCmsSubCategory(id: { eq: $id }) {
      ...SecondCategoryData
    }
    allDatoCmsArticle(
      filter: { id: { in: $currentCategoryArticles } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...DatoCmsArticleCard
      }
    }
  }
`;

import { FlexProps, Flex, Text, Box } from "@chakra-ui/react";
import { FC } from "react";
import { CustomTag } from "src/atoms";

const Desktop: FC<
  FlexProps & {
    expertises: Array<string>;
  }
> = ({ expertises, ...rest }) => {
  if (!expertises?.length) return null;

  return (
    <Box display={{ base: "none", md: "block" }}>
      <Text
        as="p"
        fontSize="14px"
        lineHeight="22px"
        mb="6"
        >
        Ses expertises&nbsp;:
      </Text>

      <Flex
        flexWrap="wrap"
        mt="6"
        {...rest}
      >
        {expertises.map((expertise) => <CustomTag
            fontFamily="body"
            key={expertise}
            mb="4"
            mr="4"
            paddingLeft="4"
            paddingRight="4"
            py="2"
            textTransform="none"
          >
            {expertise}
        </CustomTag>)}
      </Flex>
    </Box>
  );
};

export default Desktop;

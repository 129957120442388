import { DICTIONARY_WORDS } from "./constants";

const FIELD_PREFIX = 'pageSubCat';

export const getPageSubCatObj = (location: Location) => {
  const { pathname } = location;

  if (!pathname) {
    return {};
  }

  const chunks = pathname.split('/').filter((str) => !!str && str !== 'blog');
  const pageSubCatObj: { [key: string]: string } = {};

  chunks.forEach((chunk: string, index: number) => {
    const key = `${FIELD_PREFIX}${index + 1}`;
    const value = humanizeSlug(chunk);

    pageSubCatObj[key] = value;
  });

  return pageSubCatObj;
};

export const humanizeSlug = (str: string) => {
  if (!str) {
    return '';
  }

  const words = str.split('-').filter((str) => !!str);
  const enhancedWords = words.map((word) => {
    const enhancedWord = DICTIONARY_WORDS.get(word);
    const newWord = enhancedWord ?? word;

    return newWord;
  });

  const strTemp = enhancedWords
    .join(' ')
    .replace(/(^| |')bien être/g, "$1bien-être")
    .replace(/(^| |')d /g, "$1d'")
    .replace(/(^| |')l /g, "$1l'")
    .replace(/(^| |')e commerce/g, '$1e-commerce')
    .replace(/(^| |')experts comptables/g, '$1experts-comptables')
    .replace(/(^| |')micro entreprise/g, '$1micro-entreprise')
    .replace(/(^| |')start up/g, '$1start-up');

  const strWithCapitalLetter = `${strTemp.charAt(0).toUpperCase()}${strTemp.slice(1)}`;

  return strWithCapitalLetter;
};

export const buildPageProperties = () => ({
  pageTemplate: 'Blog',
  eventFunnelStep: 'Inscription'
});

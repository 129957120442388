function getCategoryLink(slug: string): string {
  return `/${slug}/`;
}

function getMultiDepthCategoryLink(slug: string, upperCategories: { title: string; slug: string; id?: string }[]) {
  const categoriesSlug = upperCategories.map(({ slug }) => slug);
  const allSlugs = [...categoriesSlug, slug];

  return getCategoryLink(allSlugs.join('/'));
}

function getMultiDepthCategoryWithPageLink(slug: string, upperCategories: { title: string; slug: string; id?: string }[], page: number) {
  const link = getMultiDepthCategoryLink(slug, upperCategories);

  if (page <= 1) {
    return link;
  }

  return `${link}${page}/`;
};

export { getCategoryLink, getMultiDepthCategoryLink, getMultiDepthCategoryWithPageLink };

import {
  Image,
  Text,
  Grid,
  Flex,
  Box,
  Heading,
  LinkBoxProps,
  LinkBox,
  LinkOverlay,
  chakra,
} from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import { getCategoryLevel } from "src/services/article/category";
import { getFormattedDates } from "src/services/article/date";
import { getArticleLink } from "src/services/article/link";
import { getReadTime } from "src/services/article/readTime";
import { getFormattedTitle } from "src/services/article/title";
import { getMultiDepthCategoryLink } from "src/services/categories/link";
import { variableTransformer } from "src/services/metadata/variable";

import FormattedDates from "./FormattedDates";

const ArticleCard: FC<Gatsby.DatoCmsArticleCardFragment & LinkBoxProps> = ({
  illustration,
  title,
  body,
  slug,
  date,
  primaryCategory,
  meta,
  ...rest
}) => {
  const readTime = getReadTime(toPlainText(body) || "", true);

  const { firstPublishedAt, updatedAt } = getFormattedDates(date!, meta);

  const upperCategories = primaryCategory
    ? getCategoryLevel(primaryCategory)
    : [];

  const formattedTitle = getFormattedTitle(variableTransformer(title!) || "");

  return (
    <LinkBox {...rest} role="group">
      <Grid
        direction={{ base: "row", md: "column" }}
        gap={{ base: "4", md: "6" }}
        minH="100%"
        templateColumns={{ base: "93px 1fr", md: "1fr" }}
        templateRows={{ md: "auto 1fr" }}
      >
        {illustration?.thumbnail && (
          <Box
            borderRadius="2xl"
            height={{ base: "93px", md: "max" }}
            overflow="hidden"
            transform="translateZ(0)"
            width={{ base: "93px", md: "unset" }}
          >
            <Image
              _groupHover={{
                transform: "scale(1.03)",
              }}
              alt={illustration?.alt || ""}
              as={GatsbyImage}
              image={illustration?.thumbnail}
              imgStyle={{
                width: "100%",
                height: "100%",
                transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1)",
              }}
              transitionDuration="normal"
              transitionProperty="common"
              transitionTimingFunction="ease-out"
            />
          </Box>
        )}

        <Flex
          alignContent="flex-start"
          display={{ base: "block", md: "flex" }}
          flex={{ md: 1 }}
          flexWrap="wrap"
        >
          <Grid
            gap={2}
            gridTemplateColumns={{ base: "1fr", xs: "1fr auto" }}
            mb={{ base: 2, md: 3 }}
            minW="100%"
          >
            {primaryCategory && (
              <Text
                color="grey.600"
                fontSize="font-12"
                letterSpacing="0.04em"
                lineHeight="shortest"
                textTransform="uppercase"
              >
                <InternalLink
                  to={getMultiDepthCategoryLink(
                    primaryCategory.slug!,
                    upperCategories.slice(0, upperCategories.length - 1)
                  )}
                >
                  {primaryCategory.title}
                </InternalLink>

                {readTime ? <chakra.span color="text.primary" ml={2} textTransform="none">
                    - {readTime}
                </chakra.span> : null}
              </Text>
            )}

            <FormattedDates
              display={{ base: "flex", md: "none" }}
              firstPublishedAt={firstPublishedAt}
              fontSize="font-11"
              iconScale={.8}
              lineHeight="22px"
              spacing={1}
              updatedAt={updatedAt}
              />
          </Grid>

          <LinkOverlay
            as={InternalLink}
            h="max"
            minW="full"
            to={getArticleLink(slug || "")}
          >
            <Heading
              _groupHover={{ color: "text.secondary" }}
              as="h3"
              mb={{ base: 2, md: 0 }}
              variant="heading-3"
            >
              {formattedTitle}
            </Heading>
          </LinkOverlay>

          <FormattedDates
            display={{ base: "none", md: "flex" }}
            firstPublishedAt={firstPublishedAt}
            mt={3.5}
            updatedAt={updatedAt}
            />
        </Flex>
      </Grid>
    </LinkBox>
  );
};

export default ArticleCard;

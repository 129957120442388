export const DICTIONARY_WORDS = new Map([
  ['a', 'à'],
  ['acre', 'ACRE'],
  ['activite', 'activité'],
  ['actualites', 'actualités'],
  ['batiment', 'bâtiment'],
  ['boite', 'boîte'],
  ['ca', 'CA'],
  ['cfe', 'CFE'],
  ['cgu', 'CGU'],
  ['cgv', 'CGV'],
  ['cheques', 'chèques'],
  ['chomage', 'chômage'],
  ['clientele', 'clientèle'],
  ['commercant', 'commerçant'],
  ['comptabilite', 'comptabilité'],
  ['creation', 'création'],
  ['cse', 'CSE'],
  ['definir', 'définir'],
  ['depenses', 'dépenses'],
  ['depot', 'dépôt'],
  ['developper', 'développer'],
  ['ecologie', 'écologie'],
  ['ecologique', 'écologique'],
  ['ecologiques', 'écologiques'],
  ['ei', 'EI'],
  ['equipe', 'équipe'],
  ['especes', 'espèces'],
  ['esthetique', 'esthétique'],
  ['etre', 'être'],
  ['etudiant', 'étudiant'],
  ['etude', 'étude'],
  ['eurl', 'EURL'],
  ['feminine', 'féminine'],
  ['fideliser', 'fidéliser'],
  ['fiscalite', 'fiscalité'],
  ['formalites', 'formalités'],
  ['generiques', 'génériques'],
  ['gerer', 'gérer'],
  ['idee', 'idée'],
  ['impot', 'impôt'],
  ['impots', 'impôts'],
  ['independant', 'indépendant'],
  ['ir', 'IR'],
  ['is', 'IS'],
  ['kbis', 'Kbis'],
  ['legal', 'légal'],
  ['legale', 'légale'],
  ['levee', 'levée'],
  ['liberale', 'libérale'],
  ['marche', 'marché'],
  ['metiers', 'métiers'],
  ['modele', 'modèle'],
  ['notoriete', 'notoriéte'],
  ['nouveautes', 'nouveautés'],
  ['pret', 'prêt'],
  ['previsionnel', 'prévisionnel'],
  ['propriete', 'propriété'],
  ['recits', 'récits'],
  ['redaction', 'rédaction'],
  ['region', 'région'],
  ['rh', 'RH'],
  ['sante', 'santé'],
  ['scm', 'SCM'],
  ['sa', 'SA'],
  ['sas', 'SAS'],
  ['sarl', 'SARL'],
  ['sasu', 'SASU'],
  ['sci', 'SCI'],
  ['siege', 'siège'],
  ['societe', 'société'],
  ['societes', 'sociétés'],
  ['tresorerie', 'trésorerie'],
  ['tva', 'TVA']
]);

import { Box, VStack, Heading, Container, Text } from "@chakra-ui/react";
import { FC } from "react";
import scrollToSection from "src/lib/scrollToSection";
import { getHeadings } from "src/services/article/summary";
import { variableTransformer } from "src/services/metadata/variable";

import { SummaryProps } from "../types";

const index: FC<SummaryProps> = ({ data }) => {
  const headings: { heading: string; sectionId: string }[] = getHeadings(data);

  return headings.length > 0 ? (
    <Container display={{ xl: "none" }} variant="block">
      <Box px="4">
        <Heading
          as="div"
          fontWeight="semibold"
          lineHeight={{ base: "taller", sm: "base" }}
          mb="6"
          variant="subtitle-medium"
        >
          Sommaire
        </Heading>

        <VStack align="start" spacing="4">
          {headings.map(({ heading, sectionId }) => {
            return (
              <Box
                key={sectionId}
              >
                <Text as="a" href={`#${sectionId}`} lineHeight="taller" onClick={(e) => scrollToSection(sectionId, e)} variant="small">
                  {variableTransformer(heading)}
                </Text>
              </Box>
            );
          })}
        </VStack>
      </Box>
    </Container>
  ) : null;
};

export default index;

import { WebPage } from 'schema-dts';

export type WebPageMarkupType = Partial<WebPage>;

const getWebPageMarkup = ({
  url,
  name,
  description,
  thumbnailUrl,
  datePublished,
  dateModified,
  ...rest
}: WebPageMarkupType): WebPage => {
  const href = typeof window !== 'undefined' ? window.location.href : '';
  const title = typeof document !== 'undefined' ? document.title : '';

  if (url || href) {
    rest.potentialAction = {
      "@type": "ReadAction",
      "@id": `${(url || href)}#action-read`, 
      target: [(url || href) as string],
    };
  }

  return {
    "@type": "WebPage", 
    "@id": `${(url || href)}#schema-page`,
    url: url || href,
    name: name || title,
    inLanguage: "fr-FR",
    description,
    thumbnailUrl,
    datePublished,
    dateModified,
    isPartOf: {
      "@type": "WebSite",
      "@id": `${process.env.GATSBY_SHINE_URL}#website`, 
    },
    ...rest,
  };
};

export default getWebPageMarkup;

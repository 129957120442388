import { Box, Container, Flex, Heading, Image, Link, Stack, Text } from "@chakra-ui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC, Fragment } from "react";
import { lg } from "src/@chakra-ui/gatsby-plugin/foundations/breakpoints";
import { CustomIcon, InternalLink } from "src/atoms";
import { CustomIconName } from "src/atoms/CustomIcon";
import { variableTransformer } from "src/services/metadata/variable";

import Desktop from "./Expertises/Desktop";
import Mobile from "./Expertises/Mobile";

type IntroType = {
  author: Gatsby.DatoCmsAuthor;
  firstArticle: Array<{ id: string; title: string; slug: string; meta: { firstPublishedAt: string } }>;
};

const Intro: FC<IntroType> = ({ author, firstArticle }) => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < (parseInt(lg.replace("em", ""), 10) * 16);

  const socials: Array<{ icon: CustomIconName, link?: string }> = [
    {
      icon: "twitter",
      link: author.twitter,
    },
    {
      icon: "linkedin",
      link: author.linkedin,
    },
    {
      icon: "facebook",
      link: author.facebook,
    },
    {
      icon: "copy",
      link: author.website,
    },
    {
      icon: "mail",
      link: `mailto:${author.email}`,
    }
  ].filter(social => !!social.link && social.link !== "mailto:");

  const firstArticleTemplate = firstArticle.length ? <Fragment>
    <Text
      as="p"
      fontSize="14px"
      lineHeight="22px"
      mb={{ base: 2, md: 6 }}
      >
      Son premier article&nbsp;:
    </Text>

    <Link
      as={InternalLink}
      display="block"
      fontFamily="heading"
      fontSize={{ base: "16px", md: "20px" }}
      fontWeight="semibold"
      lineHeight={{ base: "22px", md: "30px" }}
      mb="2"
      to={firstArticle[0].slug!}
    >
      {variableTransformer(firstArticle[0].title)}
    </Link>

    <Text as="p" fontSize="14px" lineHeight="22px">{firstArticle[0].meta.firstPublishedAt}</Text>
  </Fragment> : null;

  const expertises = author.knowsAbout?.length ? author.knowsAbout.split(',').map(expertise => expertise.trim()) : [];

  return (
    <Fragment>
      <Container mb={{ base: 0, md: 26 }} mt={{ base: 6, md: 0 }}>
        <Stack direction={{ base: "column", lg: "row" }} spacing={{ base: 0, md: "40px" }}>
          <Box width={{ base: "100%", lg: 260 }}>
            {isMobile ? <Heading
              as="h1"
              fontSize="28px"
              fontWeight="medium"
              lineHeight="38px"
              mb="6"
            >
              {author.name}{author.job ? `, ${author.job}` : null}
            </Heading> : null}

            <Image
              alt={author.image?.alt}
              as={GatsbyImage}
              borderRadius={16}
              height={{ base: 343, lg: 260 }}
              image={author.image?.gatsbyImageData}
              mb="6"
              width={{ base: "100%", lg: 260 }}
            />

            {socials.length ? <Flex
              alignItems="stretch"
              justifyContent={{ base: 'center', md: 'flex-start' }}
              mb="6"
            >
              {socials.map(social => {
                return <Link display="block" href={social.link} key={social.link} ml={{ base: 2, md: 0 }} mr="2" target="_blank">
                  <Flex alignItems="center" border="1px solid" borderColor="grey.600" borderRadius="50%" height="40px" justifyContent="center" width="40px">
                    <CustomIcon name={social.icon} />
                  </Flex>
                </Link>
              })}
            </Flex> : null}

            {firstArticle.length ? <Box display={{ base: "none", md: "block" }}>
              {firstArticleTemplate}
            </Box> : null}
          </Box>

          <Box>
            {!isMobile ? <Heading
              as="h1"
              fontSize="26px"
              fontWeight="medium"
              lineHeight="34px"
              mb="8"
            >
              {author.name}{author.job ? `, ${author.job}` : null}
            </Heading> : null}

            {author.motto ? <Text
              as="p"
              color="blue.600"
              fontSize={{ base: "32px", md: "38px" }}
              fontWeight="medium"
              lineHeight={{ base: "38px", md: "48px" }}
              mb={{ base: "12", md: "8" }}
              >
              {"“"}&nbsp;{author.motto}&nbsp;{"”"}
            </Text> : null}

            <Text
              as="p"
              fontSize={{ base: "16px", md: "26px" }}
              fontWeight={{ base: "semibold", md: "medium" }}
              lineHeight={{ base: "22px", md: "34px" }}
              mb={{ base: "6", md: "8" }}
              >
                {author.description}
            </Text>

            <Desktop expertises={expertises} />
          </Box>
        </Stack>
      </Container>

      <Mobile expertises={expertises} />

      {firstArticle.length ? <Container display={{ base: "block", md: "none" }} mb="14">
        {firstArticleTemplate}
      </Container> : null}
    </Fragment>
  );
};

export default Intro;
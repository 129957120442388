import {
  Box,
  Center,
  Flex,
  Button,
  Image,
  Stack,
  Text,
  Link,
  ButtonGroup,
  ButtonGroupProps,
} from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

import CtaArrow from "../../../images/ui/arrow-cta.svg";

const CtaCard: FC<Gatsby.DatoCmsCtaCardFragment & ButtonGroupProps> = ({
  image,
  brandLogo,
  copy,
  ctaLink,
  ctaLabel,
  ...rest
}) => {
  return (
    <ButtonGroup cursor="pointer" {...rest}>
      <Stack
        alignItems="center"
        bg={"shine.yellow"}
        borderRadius={{ base: "2xl", md: "2.5xl" }}
        direction={{ base: "row", md: "column" }}
        justifyContent="center"
        pl={{ base: 8, md: 5 }}
        pr={{ base: 13, md: 5 }}
        py={{ sm: 5, md: 10 }}
        spacing="4"
        w="full"
      >
        {image && image.gatsbyImageData && (
          <Box
            flexShrink={0}
            h="full"
            maxH={{ base: "93px", md: "133px" }}
            w={{ base: "33.3%", md: "auto" }}
          >
            <Image
              alt={image.alt || ""}
              as={GatsbyImage}
              h="full"
              image={image.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
            />
          </Box>
        )}

        <Box py={{ base: 5, md: 0 }} textAlign={{ md: "center" }} w="full">
          {brandLogo && (
            <Image
              alt={brandLogo?.alt || ""}
              maxH={{ base: "25px", md: "28px" }}
              mx={{ md: "auto" }}
              src={brandLogo?.url}
            />
          )}

          <Flex justifyContent={{ md: "center" }} mt={{ base: 2, md: 4 }}>
            <Text
              fontFamily="Value Serif Pro"
              fontSize={{ base: "font-16", md: "font-22" }}
              fontWeight="medium"
              letterSpacing="-0.02em"
              lineHeight="shorter"
              variant="article"
            >
              {variableTransformer(copy!)}
            </Text>

            <Center
              _hover={{ bg: "text.secondary" }}
              alignSelf="flex-end"
              as={Link}
              bg="text.primary"
              borderRadius="50%"
              display={{ base: "flex", md: "none" }}
              flexShrink={0}
              h="7"
              href={ctaLink}
              ml="4"
              w="7"
            >
              <Image alt="arrow" src={CtaArrow} w="3" />
            </Center>
          </Flex>

          <Button
            as={Link}
            display={{ base: "none", md: "block" }}
            href={ctaLink}
            mt={4}
            mx="auto"
            variant="shinePrimary"
          >
            {ctaLabel}
          </Button>
        </Box>
      </Stack>
    </ButtonGroup>
  );
};

export default CtaCard;

import { Flex, Button, Link, Text, Slide, Box } from "@chakra-ui/react";
import { FC, useState } from "react";
import { CustomIcon } from "src/atoms";

type MobileStickyCtaType = {
  active: boolean;
};

const MobileStickyCta: FC<MobileStickyCtaType & Gatsby.StickyMobileCtaFragment> = ({
  active,
  copy,
  ctaLabel,
  ctaLink,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <Slide direction="bottom" in={active && open} style={{ zIndex: 1100 }}>
      <Box
        background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 90%)"
        display={{ base: "block", md: "none" }}
        left="0"
        px="4"
        py="3"
        sx={{ paddingBottom: "max(16px, env(safe-area-inset-bottom, 16px))" }}
        width="100%"
      >
        <Flex
          backgroundColor="#fff"
          border="1px solid"
          borderColor="grey.800"
          borderRadius="16"
          direction="column"
          p="6"
          >
          <Flex
            alignItems="flex-start"
            justifyContent="space-between"
            mb="4"
          >
            <Text as="p" lineHeight="19px" mr="6">{copy}</Text>

            <Box
              as="button"
              display={active && open ? "flex" : "none"}
              onClick={() => setOpen(false)}
              >
              <CustomIcon boxSize="13px" name="closeNav" />
            </Box>
          </Flex>

          <Button
            as={Link}
            fontWeight="normal"
            href={ctaLink}
            lineHeight="small"
            py="2.5"
            target="_blank"
            variant="primary"
            w="100%"
            >
            {ctaLabel}
          </Button>
        </Flex>
      </Box>
    </Slide>
  );
};

export default MobileStickyCta;

import { Box, Container, Flex, Grid, Heading, Image, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import NumberOne from "src/images/numbers/1-one.svg";
import NumberTwo from "src/images/numbers/2-two.svg";
import NumberThree from "src/images/numbers/3-three.svg";
import { getLatestFormattedDate } from "src/services/article/date";
import { getArticleLink } from "src/services/article/link";
import { getReadTime } from "src/services/article/readTime";
import { getFormattedTitle } from "src/services/article/title";
import { variableTransformer } from "src/services/metadata/variable";


type FavoritesType = {
  articles: Gatsby.DatoCmsArticle[];
}

const Favorites: FC<FavoritesType> = ({ articles }) => {
  const icons = [NumberOne, NumberTwo, NumberThree];

  return (
    <Container mb={{ base: 20, md: 30 }}>
      <Box
        borderBottom={{ md: "1px" }}
        borderBottomColor={{ md: "grey.400" }}
        borderTop="1px"
        borderTopColor="grey.400"
        pb={{ md: "20" }}
      >
        <Box w="full">
          <Heading
            fontWeight="medium"
            mb="10"
            mt={{ base: 10, md: 20 }}
            variant="heading-2"
          >
            Ses contenus préférés
          </Heading>
        </Box>

        <Grid
          gap={{ base: "8", md: "10" }}
          mt={"10"}
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
        >
          {articles.map((article, index) => {
            if (!article) return null;

            const { id, slug, primaryCategory, title, body, meta, date } = article;

            const readTime = getReadTime(toPlainText(body) || "");

            const formattedTitle = getFormattedTitle(
              variableTransformer(title!) || ""
            );

            const displayedDate = getLatestFormattedDate(date!, meta);

            return (
              <LinkBox key={id}>
                <Flex alignItems="flex-start">
                  {icons[index]
                    ? <Image
                        h={{ base: "auto", md: "56px" }}
                        mr="2"
                        src={icons[index]}
                        w="56px"
                      />
                    : null}

                  <Box>
                    <Box
                      display={{ base: "block", md: "none" }}
                      mb="2"
                    >
                      {primaryCategory?.title ? <Text
                          as="span"
                          color="gray.500"
                          fontSize="11px"
                          lineHeight="14px"
                          mr="4"
                          textTransform="uppercase"
                        >{primaryCategory?.title}</Text> : null}

                      {displayedDate ? <Text
                          as="span"
                          fontSize="11px"
                          lineHeight="14px"
                        >{displayedDate}</Text> : null}
                    </Box>

                    <Heading
                      _hover={{ color: "text.secondary" }}
                      as="h3"
                      transitionDuration="fast"
                      transitionProperty="common"
                      transitionTimingFunction="ease-out"
                      variant="heading-3"
                    >
                      <LinkOverlay
                        as={InternalLink}
                        to={getArticleLink(slug || "")}
                      >
                        {formattedTitle}
                      </LinkOverlay>
                    </Heading>

                    {readTime ? <Text
                        display={{ base: "none", md: "block" }}
                        fontSize="14"
                        lineHeight="22px"
                        mt="2"
                      >
                        {readTime}
                      </Text> : null}
                  </Box>
                </Flex>
              </LinkBox>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
}

export default Favorites;

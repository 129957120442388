import { BoxProps, Box } from "@chakra-ui/react";
import { FC, useState } from "react";
import Slider from "react-slick";
import { CustomTag, InternalLink } from "src/atoms";
import CustomArrow from "src/atoms/Buttons/CustomArow";

const Mobile: FC<
  BoxProps & {
    subCategories: { title: string; id: string; link: string }[];
  }
> = ({ subCategories, ...rest }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const settings = {
    variableWidth: true,
    infinite: false,
    prevArrow: <CustomArrow direction="previous" visible={slideIndex !== 0} />,
    nextArrow: (
      <CustomArrow
        direction="next"
        visible={slideIndex !== subCategories.length - 1}
      />
    ),
    beforeChange: (current: number, next: number) => setSlideIndex(next),
  };

  return (
    <Box
      {...rest}
      display={{ base: "block", md: "none" }}
      maxW="100vw"
      overflow="hidden"
      position="relative"
    >
      <Box
        background="linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 91.67%)"
        height="full"
        pointerEvents="none"
        position="absolute"
        right="0"
        top="0"
        w="11"
        zIndex="1"
      />

      <Box
        as={Slider}
        {...settings}
        position="relative"
        sx={{
          ".slick-slide": { mx: 1 },
          ".slick-list": { mx: 4, overflow: "visible" },
        }}
      >
        {subCategories.map((subCategory) => {
          return (
            <InternalLink key={subCategory.id} to={subCategory.link}>
              <CustomTag
                fontSize="font-12"
                paddingLeft="6"
                paddingRight="6"
                paddingY="2"
                textTransform="none"
              >
                {subCategory.title}
              </CustomTag>
            </InternalLink>
          );
        })}
      </Box>
    </Box>
  );
};

export default Mobile;

import { Container, Heading, Box, ContainerProps } from "@chakra-ui/react";
import { FC, Fragment } from "react";

import Carousel from "../Carousel";
import GeneralGrid from "../GeneralGrid";

import ToolCard from "./ToolCard";

const PracticalTools: FC<Gatsby.DatoCmsPracticalToolFragment & ContainerProps> =
  ({ title, tools, ...rest }) => {
    return (
      <Container overflow="visible" variant="block" {...rest}>
        <Container display={{ base: "block", md: "none" }}>
          <Box mb={{ base: 4, md: 0 }}>
            <Heading variant="heading-1">{title}</Heading>
          </Box>

          <GeneralGrid>
            {tools?.map((tool) => {
              return (
                <Fragment key={tool?.id}>
                  <ToolCard {...tool!} />
                </Fragment>
              );
            })}
          </GeneralGrid>
        </Container>

        <Carousel
          display={{ base: "none", md: "block" }}
          length={tools?.length || 0}
          title={title}
        >
          {tools?.map((tool) => {
            return (
              <Box key={tool?.id}>
                <Box w={{ base: "224px", "2xl": "200px" }}>
                  <ToolCard {...tool!} />
                </Box>
              </Box>
            );
          })}
        </Carousel>
      </Container>
    );
  };

export default PracticalTools;

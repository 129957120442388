import { Button, Heading, Box, Input, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

const MobileSubscribe: FC<Gatsby.EmailSubscribeFragment> = ({
  copy,
  mobileCopy,
}) => {
  return (
    <VStack align="flex-start" spacing="4">
      <Heading variant="subtitle-large">
        {mobileCopy ? mobileCopy : copy}
      </Heading>

      <form>
        <Box
          border="1px"
          borderColor="text.primary"
          borderRadius="full"
          color="text.primary"
          position="relative"
        >
          <Input
            _focus={{ outline: "none" }}
            _placeholder={{ color: "text.primary" }}
            border="none"
            height="48px"
            pl="6"
            pr="13"
            py="4"
            textAlign="center"
          />

          <Button
            bg="text.primary"
            borderRadius="50%"
            h="12"
            position="absolute"
            right="0"
            top="calc(100% - 48px)"
            type="submit"
            variant="reset"
            w="12"
          >
            <CustomIcon name="arrow" />
          </Button>
        </Box>
      </form>
    </VStack>
  );
};

export default MobileSubscribe;

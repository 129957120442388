import { Container, Heading } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import { FC, Fragment } from "react";
import CtaCard from "src/components/CollectionPage/CtaCard";
import Breadcrumbs from "src/components/Core/Breadcrumbs";
import CollectionCard from "src/components/Core/CollectionCard";
import GeneralGrid from "src/components/Core/GeneralGrid";
import PageBlock from "src/components/Core/PageBlock";
import Seo from "src/components/Core/Seo";
import { breadcrumbMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import { getCollectionLink } from "src/services/collection/link";

const CollectionPage: FC<PageProps<Gatsby.CollectionPageQuery>> = ({
  data: { datoCmsCollectionPage, allDatoCmsCollection },
}) => {
  if (!datoCmsCollectionPage) return null;

  const { slug, title, blocks, ctaCard } = datoCmsCollectionPage;

  const list: (
    | Gatsby.DatoCmsCollectionCardFragment
    | Gatsby.DatoCmsCtaCardFragment
  )[] = allDatoCmsCollection.nodes ? [...allDatoCmsCollection.nodes] : [];

  if (ctaCard) {
    list.splice(7, 0, ctaCard!);
  }

  const link = getCollectionLink(slug!);

  return (
    <Layout>
      <Seo
        breadcrumbMarkup={breadcrumbMarkup([], { name: title!, path: link })}
        path={`/${slug}/`}
        seo={datoCmsCollectionPage.seo!}
      />

      <Breadcrumbs current={{ title, link }} />

      <Container>
        <Container variant="block">
          <Heading
            as="h1"
            mb={{ base: 4, md: 10 }}
            mt={{ base: 4, md: 2 }}
            variant="heading-1"
          >
            {title}
          </Heading>

          <GeneralGrid>
            {list?.map((card) => {
              if (!card) return null;

              return (
                <Fragment key={card.id}>
                  {card.__typename === "DatoCmsCollection" ? (
                    <CollectionCard {...card} />
                  ) : (
                    <CtaCard {...card} />
                  )}
                </Fragment>
              );
            })}
          </GeneralGrid>
        </Container>
      </Container>

      {blocks &&
        blocks.map((block) => {
          return <PageBlock {...block!} key={block?.id} />;
        })}
    </Layout>
  );
};

export default CollectionPage;

export const query = graphql`
  query CollectionPage($id: String) {
    datoCmsCollectionPage(id: { eq: $id }) {
      ...CollectionPageData
    }
    allDatoCmsCollection {
      nodes {
        ...DatoCmsCollectionCard
      }
    }
  }
`;

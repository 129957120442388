export { ReactComponent as arrowCircle } from "src/images/ui/arrow-carousel.svg";
export { ReactComponent as arrowCircleSm } from "src/images/ui/arrow-circle-small.svg";
export { ReactComponent as arrow } from "src/images/ui/arrow-cta.svg";
export { ReactComponent as arrowLeft } from "src/images/ui/arrow-left.svg";
export { ReactComponent as arrowRight } from "src/images/ui/arrow-right.svg";
export { ReactComponent as burger } from "src/images/ui/burger.svg";
export { ReactComponent as closeBlueLg } from "src/images/ui/close-faq.svg";
export { ReactComponent as closeNav } from "src/images/ui/close-nav.svg";
export { ReactComponent as close } from "src/images/ui/close.svg";
export { ReactComponent as edit } from "src/images/ui/edit.svg";
export { ReactComponent as history } from "src/images/ui/history.svg";
export { ReactComponent as magnifier } from "src/images/ui/magnifier.svg";
export { ReactComponent as plus } from "src/images/ui/plus.svg";
export { ReactComponent as swipeRight } from "src/images/ui/swipe-right.svg";
export { ReactComponent as copy } from "src/images/ui/social/copy.svg";
export { ReactComponent as copyPaste } from "src/images/ui/social/copy-paste.svg";
export { ReactComponent as facebook } from "src/images/ui/social/facebook.svg";
export { ReactComponent as linkedin } from "src/images/ui/social/linkedin.svg";
export { ReactComponent as mail } from "src/images/ui/social/mail.svg";
export { ReactComponent as twitter } from "src/images/ui/social/twitter.svg";
export { ReactComponent as youtube } from "src/images/ui/social/youtube.svg";
export { ReactComponent as instagram } from "src/images/ui/social/instagram.svg";
export { ReactComponent as star } from "src/images/ui/star.svg";
export { ReactComponent as appStore } from "src/images/ui/app-store.svg";
export { ReactComponent as playStore } from "src/images/ui/play-store.svg";
export { ReactComponent as trustpilot } from "src/images/ui/trustpilot.svg";

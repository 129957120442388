import { Record } from "datocms-structured-text-utils";
import { StructuredTextPropTypes } from "react-datocms";
import DatoCmsBlockquoteemoji from "src/blocks/DatoCmsBlockQuoteEmoji";
import DatoCmsContainedImage from "src/blocks/DatoCmsContainedImage";
import DatoCmsCtaCardBlock from "src/blocks/DatoCmsCtaCardBlock";
import DatoCmsDoubleImage from "src/blocks/DatoCmsDoubleImage";
import DatoCmsFaqBlock from "src/blocks/DatoCmsFaqBlock";
import DatoCmsFullWidthImage from "src/blocks/DatoCmsFullWidthImage";
import DatoCmsHighlight from "src/blocks/DatoCmsHighlight";
import DatoCmsHubspotForm from "src/blocks/DatoCmsHubspotForm";
import DatoCmsRecap from "src/blocks/DatoCmsRecap";
import DatoCmsTable from "src/blocks/DatoCmsTable";
import DatoCmsVideo from "src/blocks/DatoCmsVideo";
import Placeholder from "src/components/__dev__/Placeholder";

const BLOCKS = {
  DatoCmsHighlight,
  DatoCmsRecap,
  DatoCmsFullWidthImage,
  DatoCmsContainedImage,
  DatoCmsDoubleImage,
  DatoCmsCtaCardBlock,
  DatoCmsVideo,
  DatoCmsBlockquoteemoji,
  DatoCmsFaqBlock,
  DatoCmsTable,
  DatoCmsHubspotForm
};

const isRegisteredBlock = (typename: string): typename is keyof typeof BLOCKS =>
  typename in BLOCKS;

/**
 * Given a block's data, render its corresponding components or a placeholder if we don't have one
 */
const renderBlock: StructuredTextPropTypes<Record>["renderBlock"] = (block) => {
  const Block = isRegisteredBlock(block.record.__typename)
    ? BLOCKS[block.record.__typename]
    : null;

  if (Block) return <Block {...(block.record as any)} />;

  if (process.env.NODE_ENV !== "development") return null;

  return (
    <Placeholder title={`Rendering Block ${block.record.id}`}>
      {block}
    </Placeholder>
  );
};

export default renderBlock;

import { Box, Stack, Image, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsDoubleImage: FC<Gatsby.DatoCmsDoubleImageFragment> = ({
  images,
}) => {
  if (!images) return null;

  return (
    <Box overflow="hidden">
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="center"
        spacing="8"
      >
        {images.map((image) => {
          const { media, caption } = image!;

          return media ? (
            <Box key={media.gatsbyImageData}>
              <Image
                alt={media.alt || ""}
                as={GatsbyImage}
                image={media.gatsbyImageData}
                imgStyle={{ borderRadius: "16px" }}
              />

              {caption && (
                <Text
                  color="text.cta"
                  mt={4}
                  textAlign="center"
                  variant="small"
                >
                  {variableTransformer(caption)}
                </Text>
              )}
            </Box>
          ) : null;
        })}
      </Stack>
    </Box>
  );
};

export default DatoCmsDoubleImage;

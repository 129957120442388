const getFormattedDates = (date: Date, meta?: Pick<Gatsby.ArticleDataFragment, 'meta'>) => {
  if (!date) throw new Error('Date is required');

  const firstPublishedAt = meta?.formattedFirstPublishedAt || date.toLocaleString('fr-FR', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
  const beenUpdated = getBeenUpdated(date, meta);

  return {
    firstPublishedAt,
    updatedAt: beenUpdated ? meta?.formattedUpdatedAt : null,
  }
}

const getBeenUpdated = (date: Date, meta?: Pick<Gatsby.ArticleDataFragment, 'meta'>) => {
  if (!date) throw new Error('Date is required');

  const publishedDate = new Date(meta?.firstPublishedAt || date!);
  const updatedDate = meta?.updatedAt
    ? new Date(meta?.updatedAt)
    : publishedDate;

  return updatedDate > publishedDate && updatedDate.toDateString() !== publishedDate.toDateString();
}

const getLatestFormattedDate = (date: Date, meta?: Pick<Gatsby.ArticleDataFragment, 'meta'>) => {
  const beenUpdated = getBeenUpdated(date, meta);

  return beenUpdated && meta?.formattedUpdatedAt
    ? meta.formattedUpdatedAt
    : meta?.formattedFirstPublishedAt
      ? meta.formattedFirstPublishedAt
      : date.toLocaleString('fr-FR', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
      });
}

export {
  getFormattedDates,
  getBeenUpdated,
  getLatestFormattedDate,
}

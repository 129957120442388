import { Box, Text } from "@chakra-ui/react";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsVideo: FC<Gatsby.DatoCmsVideoFragment> = ({ media, caption }) => {
  if (!media) return null;

  return (
    <Box my={{ base: "6", lg: "10" }}>
      <Box
        as="video"
        autoPlay
        borderRadius="2xl"
        loop
        muted
        playsInline
        preload="metadata"
        src={media.url}
      />

      {caption && (
        <Text color="text.cta" mt={4} textAlign="center" variant="small">
          {variableTransformer(caption)}
        </Text>
      )}
    </Box>
  );
};

export default DatoCmsVideo;

import { chakra, Container, Heading } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import { FC, useState, useEffect, useLayoutEffect, Fragment } from "react";
import ArticleCard from "src/components/Core/ArticleCard";
import ArticleGrid from "src/components/Core/ArticleGrid";
import Breadcrumbs from "src/components/Core/Breadcrumbs";
import PageBlock from "src/components/Core/PageBlock";
import Seo from "src/components/Core/Seo";
import { breadcrumbMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import { getSearchResult } from "src/services/search/search";

const Result: FC<PageProps<Gatsby.ResultQuery>> = ({
  data: {
    datoCmsResultPage,
    allDatoCmsArticle: { articles },
  },
  location,
}) => {
  if (!datoCmsResultPage) return null;

  const { slug, title, blocks, seo } = datoCmsResultPage;

  const [search, setSearch] = useState<string>();
  const [result, setResult] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState(articles || []);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParam = searchParams.get("q");

    if (searchParam) {
      const decodedSearchParam = decodeURI(searchParam);

      setSearch(decodedSearchParam);
      getSearchResult(decodedSearchParam).then((res) => setResult(res));
    }
  }, [location.search]);

  useEffect(() => {
    if (result) {
      const resultAttributes = result.map(({ attributes }) => attributes);
      const articleScores = {}

      const articlesInResult = articles.filter((article) => {
        const { slug } = article;

        const articleInResult = resultAttributes.find((attr) => attr.url.includes(slug));

        if (articleInResult) {
          articleScores[article.id] = articleInResult.score;
        }

        return !!articleInResult;
      });

      articlesInResult.sort((a, b) => {
        const aYear = Number(a.meta!.year) || 0;
        const bYear = Number(b.meta!.year) || 0;
        const aScore = articleScores[a.id] || 0;
        const bScore = articleScores[b.id] || 0;

        if (aYear !== bYear) {
          return bYear - aYear
        }

        return bScore - aScore; 
      });

      setFilteredArticles(articlesInResult);
    }
  }, [result]);

  return (
    <Layout>
      <Seo
        breadcrumbMarkup={breadcrumbMarkup([], { name: title!, path: slug! })}
        path={`/${slug}/`}
        seo={seo!}
      />

      <Breadcrumbs
        current={{
          title: title!,
          link: `${slug}`,
        }}
        currentOnly
      />

      <Container>
        <Container variant="block">
          <Heading as="h1" mb={{ base: 4, sm: 6 }} variant="heading-1">
            Recherche pour
            <chakra.span color="text.cta">{` "${search}"`}</chakra.span>
          </Heading>

          <ArticleGrid>
            {filteredArticles.map((article) => {
              return (
                <Fragment key={article?.id}>
                  <ArticleCard {...article} />
                </Fragment>
              );
            })}
          </ArticleGrid>
        </Container>
      </Container>

      {blocks &&
        blocks.map((block) => {
          return <PageBlock {...block!} key={block?.id} />;
        })}
    </Layout>
  );
};

export default Result;

export const query = graphql`
  query Result {
    datoCmsResultPage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      title
      blocks {
        ...DatoCmsMostReadArticlesBlock
        ...DatoCmsGlobalCtaBlock
      }
    }
    allDatoCmsArticle(sort: { fields: date, order: DESC }) {
      articles: nodes {
        ...DatoCmsArticleCardSearchResult
      }
    }
  }
`;

import {
  Box,
  Button,
  Image,
  VStack,
  Text,
  LinkBox,
  LinkOverlay,
  LinkBoxProps,
} from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";

import CtaArrow from "../../images/ui/arrow-cta.svg";

const CtaCard: FC<Gatsby.DatoCmsCtaCardFragment & LinkBoxProps> = ({
  image,
  brandLogo,
  copy,
  ctaLink,
  ctaLabel,
  ...rest
}) => {
  return (
    <LinkBox {...rest} h="full" role="group" w="full">
      <VStack
        alignItems="center"
        bg={"blue.100"}
        borderRadius="3xl"
        h="full"
        justifyContent="center"
        px={{ base: 1, md: 4 }}
        py="4"
        spacing="2"
        textAlign="center"
        w="full"
      >
        {image && image.gatsbyImageData && (
          <Box
            display={{ base: "block", md: "none" }}
            h="full"
            maxH="7"
            w="33.3%"
          >
            <Image
              alt={image.alt || ""}
              as={GatsbyImage}
              h="full"
              image={image.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              maxH="93px"
            />
          </Box>
        )}

        {brandLogo && (
          <Image
            alt={brandLogo?.alt || ""}
            maxH={{ base: "20px", md: "24px", "2xl": "35px" }}
            mx={{ md: "auto" }}
            src={brandLogo?.url}
          />
        )}

        <Text
          fontSize={{ base: "font-16", md: "font-20" }}
          fontWeight="bold"
          lineHeight="shorter"
          variant="article"
        >
          {copy}
        </Text>

        <LinkOverlay href={ctaLink}>
          <Button
            borderRadius="50%"
            display={{ base: "none", md: "flex" }}
            maxH="7"
            maxW="7"
            minH="7"
            minW="7"
            mx="auto"
            p="0"
          >
            <Image alt="arrow" h="3" minH="3" minW="3" src={CtaArrow} w="3" />
          </Button>
        </LinkOverlay>
      </VStack>
    </LinkBox>
  );
};

export default CtaCard;

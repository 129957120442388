import {
  Box,
  Image,
  Text,
  Button,
  Link,
  Stack
} from "@chakra-ui/react";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsCtaCard: FC<Gatsby.DatoCmsCtaCardBlockFragment> = ({
  ctaCard,
}) => {
  const { image, brandLogo, ctaLabel, ctaLink, copy } = ctaCard!;

  return (
    <Stack
      alignItems="center"
      bg="shine.yellow"
      borderRadius="3xl"
      direction={{ base: "column", md: "row" }}
      maxW={{ base: "400px", md: "full" }}
      mx="auto"
      my={{ base: "10" }}
      p="8"
      spacing={{ base: 4, md: 6 }}
    >
      <Image
        alt={image?.alt || ""}
        flexShrink={0}
        maxH="100px"
        maxW={{ base: "80%", md: "100px" }}
        objectFit="contain"
        src={image?.url}
      />

      <Stack
        alignItems="center"
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 4, md: 6 }}
      >
        <Stack
          alignItems={{ base: "center", md: "flex-start" }}
          spacing={{ base: 4, md: 2 }}
        >
          {brandLogo && (
            <Image
              alt={brandLogo.alt || ""}
              maxW="80px"
              src={brandLogo.url}
            />
          )}

          <Text
            fontFamily="Value Serif Pro"
            fontSize="font-22"
            fontWeight="normal"
            letterSpacing="-0.02em"
            lineHeight="28px"
            textAlign={{ base: "center", md: "left" }}
            variant="article"
          >
            {variableTransformer(copy!)}
          </Text>
        </Stack>

        <Box
          alignSelf="center"
          w={{ base: "full", md: "max" }}
        >
          <Button as={Link} href={ctaLink} variant="shinePrimary" w={{ base: "full", md: "max" }}>
            {ctaLabel}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default DatoCmsCtaCard;

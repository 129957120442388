import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { isHeading } from "datocms-structured-text-utils";

import { getSectionId } from "./index";
import { variableTransformer } from "src/services/metadata/variable";

type Heading = { heading: string; sectionId: string };

export default function getHeadings(
  data: Gatsby.ArticleDataFragment["body"]
): Heading[] {
  const headings: Heading[] = [];

  if (data) {
    data.value.document.children.forEach((child: any) => {
      const heading = isHeading(child);

      if (heading && child.level === 2) {
        const headerText = variableTransformer(toPlainText(child) || '');

        if (headerText) {
          headings.push({
            heading: headerText,
            sectionId: getSectionId(headerText),
          });
        }
      }
    });
  }

  return headings;
}

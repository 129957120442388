import { Text, Flex, Stack, Image, Link, LinkProps } from "@chakra-ui/react";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import { getCollectionLink } from "src/services/collection/link";

const CollectionCard: FC<Gatsby.DatoCmsCollectionCardFragment & LinkProps> = ({
  name,
  slug,
  icon,
  articleList,
  ...rest
}) => {
  const articleLength =
    articleList?.filter((item) => item?.__typename === "DatoCmsArticle")
      .length || 0;

  return (
    <Link as={InternalLink} to={getCollectionLink(slug || "")} {...rest}>
      <Stack
        _hover={{ bg: "grey.400" }}
        align="center"
        bg="grey.200"
        borderRadius="2xl"
        h="full"
        minH={{ base: "380px", md: "423px" }}
        p={{ base: 6, md: 8, lg: 6, "2xl": 8 }}
        pb={{ base: 8, lg: 6, "2xl": 10 }}
        spacing={{ base: 2, md: 4 }}
        transitionDuration="normal"
        transitionProperty="common"
        transitionTimingFunction="ease-out"
        w="full"
      >
        {icon ? <Image alt={icon.alt || ""} src={icon.url} w="180px" /> : null}

        <Flex
          alignContent="space-between"
          flexGrow={1}
          flexWrap="wrap"
          w="full"
        >
          <Text
            fontSize={{ base: "font-22", md: "font-26" }}
            fontWeight={{ base: "semibold", md: "medium" }}
            lineHeight={{ base: "short", md: "shorter" }}
            minW="full"
          >
            {name}
          </Text>

          <Text fontSize="font-12" mt={6}>
            {`${articleLength} article${articleLength > 1 && "s"}`}
          </Text>
        </Flex>
      </Stack>
    </Link>
  );
};

export default CollectionCard;

import { Upload } from 'datocms-plugin-sdk'
import { StructuredTextDocument } from 'react-datocms'

enum CellType {
  StructuredText = 'structuredText',
  Image = 'image'
}

type Cell = {
  id: string
  type: CellType.StructuredText
  value: StructuredTextDocument
} | {
  id: string
  type: CellType.Image
  value: Upload
}

type TableOptions = {
  mainRow: number
  mainColumn: number
}

type Table = {
  title?: string
  options: TableOptions,
  data: Array<Array<Cell>>
}

export { CellType, Cell, TableOptions, Table }

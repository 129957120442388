import heading from "./heading";
import link from "./link";
import list from "./list";
import listItem from "./listItem";
import paragraph from "./paragraph";
import thematicBreak from "./thematicBreak";

/**
 * Find the full list of possible guard rules in node_modules/datocms-structured-text-utils/dist/types/guards.d.ts
 */
const rules = [heading, paragraph, list, listItem, link, thematicBreak];

export default rules;

const getCategoryLevel = (
  category:
    | Gatsby.ArticleCategoryFirstLevelFragment
    | Gatsby.ArticleCategorySecondLevelFragment
    | Gatsby.ArticleCategoryThirdLevelFragment
    | Gatsby.ArticleCategoryFourthLevelFragment
    | Gatsby.ArticleCategoryFifthLevelFragment
    | Gatsby.ArticleCategorySixthLevelFragment
    | Gatsby.ArticleCategorySeventhLevelFragment
    | Gatsby.ArticleCategoryEighthLevelFragment
    | Gatsby.ArticleCategoryNinthLevelFragment
    | Gatsby.ArticleCategoryTenthLevelFragment,
  categoryArray: { title: string; slug: string; id?: string }[] = []
) => {
  categoryArray.unshift({ title: category.title!, slug: category.slug! });

  if (category?.category) {
    getCategoryLevel(category.category, categoryArray);
  }

  return categoryArray;
};

export { getCategoryLevel };

const linkResolver = (link: string): string => {
  let resolvedLink = link;

  if (resolvedLink && !resolvedLink.startsWith("/"))
    resolvedLink = `/${resolvedLink}`;

  if (resolvedLink && !resolvedLink.includes('#') && !resolvedLink.endsWith("/"))
    resolvedLink = `${resolvedLink}/`;

  return resolvedLink;
};

export { linkResolver };

import { Box, Text } from "@chakra-ui/react";
import { isParagraph, renderRule } from "datocms-structured-text-utils";
import { FC } from "react";
import StructuredText from "src/components/StructuredText";

const DatoCmsHighlight: FC<Gatsby.DatoCmsHighlightFragment> = ({ body }) => (
  <Box my={{ base: "12", lg: "16" }}>
    <StructuredText
      customRules={[
        renderRule(isParagraph, ({ children, key }) => (
          <Text
            color="blue.600"
            fontSize={{ base: "font-32", lg: "font-40" }}
            fontWeight="medium"
            key={key}
            lineHeight="shortest"
            textAlign="center"
          >
            {children}
          </Text>
        )),
      ]}
      data={body}
    />
  </Box>
);

export default DatoCmsHighlight;

import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { useEffect } from "react";
import ArticleList from "src/components/Category/ArticleList";
import Intro from "src/components/Category/Intro";
import SelectedArticles from "src/components/Category/SelectedArticles";
import Breadcrumbs from "src/components/Core/Breadcrumbs";
import CollectionsCarousel from "src/components/Core/CollectionsCarousel";
import MostReadArticles from "src/components/Core/MostReadArticles";
import Seo from "src/components/Core/Seo";
import { breadcrumbMarkup } from "src/components/Core/Seo/Markups/utils";
import Layout from "src/components/Layout";
import { getBreadcrumbs } from "src/services/categories/breadcrumbs";
import { getMultiDepthCategoryLink } from "src/services/categories/link";


type CategoryType = {
  pageIndex: number;
  upperCategories: { title: string; slug: string }[];
  category:
    | Gatsby.FirstCategoryDataFragment
    | Gatsby.SecondCategoryDataFragment
    | Gatsby.ThirdCategoryDataFragment
    | Gatsby.FourthCategoryDataFragment
    | Gatsby.FifthCategoryDataFragment
    | Gatsby.SixthCategoryDataFragment
    | Gatsby.SeventhCategoryDataFragment
    | Gatsby.EighthCategoryDataFragment
    | Gatsby.NinthCategoryDataFragment
    | Gatsby.TenthCategoryDataFragment;
  categoryTitle: string;
  articles: Gatsby.DatoCmsArticleCardFragment[];
  seo: Gatsby.GatsbyDatoCmsSeoMetaTagsFragment | null;
  hotjar?: boolean;
  subCategories: { title: string; id: string; link: string }[];
  totalPages: number;
  path: string;
};

const Category = ({
  pageIndex,
  upperCategories,
  category,
  articles,
  categoryTitle,
  seo,
  hotjar,
  subCategories,
  totalPages,
  path
}: CategoryType) => {
  if (!category) return null;

  const { datoCmsMostReadArticle } =
    useStaticQuery<Gatsby.DatoCmsCategoryBlockQuery>(
      graphql`
        query DatoCmsCategoryBlock {
          datoCmsMostReadArticle {
            ...DatoCmsMostReadArticle
          }
        }
      `
    );

  const { slug, intro, selectedArticles } = category;
  const location = useLocation();

  useEffect(() => {
    const { href } = location;
    const url = new URL(href);

    if (url.searchParams.has('page')) {
      url.searchParams.delete('page');
      history.replaceState(null, "", url.toString());
    }
  }, []);

  // link should be without any page information
  const link = getMultiDepthCategoryLink(slug!, upperCategories!);
  const crumbs = getBreadcrumbs(upperCategories!);

  return (
    <Layout>
      <Seo
        breadcrumbMarkup={breadcrumbMarkup(crumbs, { name: categoryTitle, path: link })}
        hotjar={hotjar}
        path={path}
        seo={seo!}
      />

      <Breadcrumbs
        crumbs={crumbs}
        current={{
          link,
          title: categoryTitle
        }}
      />

      <Intro
        intro={intro!}
        subCategories={subCategories!}
        title={categoryTitle!}
      />

      <SelectedArticles articles={selectedArticles!} />

      <ArticleList
        articles={articles}
        link={link}
        pageIndex={pageIndex}
        totalPages={totalPages}
      />

      <CollectionsCarousel />

      <MostReadArticles {...datoCmsMostReadArticle!} />
    </Layout>
  );
};

export default Category;

import { BoxProps, Box, Container, Text } from "@chakra-ui/react";
import { FC, useState } from "react";
import Slider from "react-slick";
import { CustomTag } from "src/atoms";
import CustomArrow from "src/atoms/Buttons/CustomArow";

const Mobile: FC<
  BoxProps & {
    expertises: Array<string>;
  }
> = ({ expertises, ...rest }) => {
  if (!expertises?.length) return null;

  const [slideIndex, setSlideIndex] = useState(0);
  const settings = {
    variableWidth: true,
    infinite: false,
    prevArrow: <CustomArrow direction="previous" visible={slideIndex !== 0} />,
    nextArrow: (
      <CustomArrow
        direction="next"
        visible={slideIndex !== expertises.length - 1}
      />
    ),
    beforeChange: (current: number, next: number) => setSlideIndex(next),
  };

  return (
    <Box
      {...rest}
      display={{ base: "block", md: "none" }}
      mb={{ base: 10, md: 30 }}
    >

      <Container>
        <Text
          as="p"
          fontSize="14px"
          lineHeight="22px"
          mb="2"
          >
          Ses expertises&nbsp;:
        </Text>
      </Container>

      <Box
        maxW="100vw"
        overflow="hidden"
        position="relative"
      >
        <Box
          background="linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 91.67%)"
          height="full"
          pointerEvents="none"
          position="absolute"
          right="0"
          top="0"
          w="11"
          zIndex="1"
        />

        <Box
          as={Slider}
          {...settings}
          position="relative"
          sx={{
            ".slick-slide": { mx: 1 },
            ".slick-list": { mx: 4, overflow: "visible" },
          }}
        >
          {expertises.map((expertise) => <CustomTag
            fontFamily="body"
            fontSize="font-12"
            key={expertise}
            px="6"
            py="2"
            textTransform="none"
          >
            {expertise}
          </CustomTag>)}
        </Box>
      </Box>
    </Box>
  );
};

export default Mobile;

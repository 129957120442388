import { Box, Heading, Container, BoxProps } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC } from "react";
import ArticleCard from "src/components/Core/ArticleCard";
import ArticleGrid from "src/components/Core/ArticleGrid";
import Pagination from "src/components/Core/Pagination";

import CtaCard from "../../Core/ArticleGrid/CtaCard";

const ArticleList: FC<
  BoxProps & {
    articles: Gatsby.DatoCmsArticleCardFragment[];
    totalPages?: number;
    pageIndex?: number;
    link?: string;
  }
> = ({ articles, totalPages, pageIndex, link, ...rest }) => {
  if (!articles || articles.length === 0) return null;

  const { datoCmsGlobalCta } =
    useStaticQuery<Gatsby.DatoCmsCategoryArticleListCtaCardQuery>(
      graphql`
        query DatoCmsCategoryArticleListCtaCard {
          datoCmsGlobalCta {
            ...DatoCmsGlobalCta
          }
        }
      `
    );

  const list: (
    | Gatsby.DatoCmsArticleCardFragment
    | Gatsby.DatoCmsCtaCardFragment
  )[] = [...articles];

  if (datoCmsGlobalCta) {
    list.push(datoCmsGlobalCta.cta!);
  }

  return (
    <Box {...rest}>
      <Container variant="block">
        <Container>
          <Heading
            as="h2"
            fontWeight="medium"
            lineHeight={{ base: "shortest", md: "shorter" }}
            mb={{ base: 6, md: 10 }}
            variant="heading-2"
          >
            Tous les articles
          </Heading>

          <ArticleGrid columns={{ base: 1, md: 3, xl: 4 }}>
            {list.map((card) => {
              if (card.__typename === "DatoCmsArticle") {
                return <ArticleCard {...card!} key={card.id} />;
              } else {
                return (
                  <CtaCard
                    {...card}
                    display={{ base: "none", md: "block", xl: "none" }}
                    key={card.id}
                  />
                );
              }
            })}
          </ArticleGrid>

          {totalPages && totalPages > 1 && (
            <Pagination current={pageIndex} link={link} total={totalPages} />
          )}
        </Container>
      </Container>
    </Box>
  );
};

export default ArticleList;

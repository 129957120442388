import { Text, Heading, Image, Link } from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { InternalLink } from "src/atoms";
import { getCategoryLevel } from "src/services/article/category";
import { getArticleLink } from "src/services/article/link";
import { getReadTime } from "src/services/article/readTime";
import { getFormattedTitle } from "src/services/article/title";
import { getMultiDepthCategoryLink } from "src/services/categories/link";
import { variableTransformer } from "src/services/metadata/variable";

const ArticleCard: FC<Gatsby.RelatedArticlesFragment> = ({
  slug,
  title,
  illustration,
  body,
  date,
  primaryCategory,
}) => {
  const readTime = getReadTime(toPlainText(body) || "", true);
  const articleLink = getArticleLink(slug!);

  const formattedTitle = getFormattedTitle(variableTransformer(title!) || "");

  const upperCategories = primaryCategory
    ? getCategoryLevel(primaryCategory)
    : [];

  const readTimeAndOrDate = !!readTime ? `${readTime} · ${date}` : date;

  return (
    <Link as={InternalLink} role="group" to={articleLink}>
      <Image
        alt={illustration?.alt || ""}
        as={GatsbyImage}
        image={illustration?.gatsbyImageData}
        imgStyle={{ borderRadius: "16px" }}
      />

      {primaryCategory && (
        <Text
          color="grey.600"
          fontSize="font-12"
          letterSpacing="0.04em"
          lineHeight="shortest"
          minH={{ base: "unset", md: "28px", "2xl": "unset" }}
          mt="6"
          textTransform="uppercase"
        >
          <InternalLink
            to={getMultiDepthCategoryLink(
              primaryCategory.slug!,
              upperCategories.slice(0, upperCategories.length - 1)
            )}
          >
            {primaryCategory.title}
          </InternalLink>
        </Text>
      )}

      <Heading
        _groupHover={{ color: "text.secondary" }}
        as="h3"
        lineHeight="base"
        mt={{ base: "2", md: "4" }}
        transitionDuration="normal"
        transitionProperty="common"
        transitionTimingFunction="ease-out"
        variant="heading-3"
      >
        {formattedTitle}
      </Heading>

      <Text
        display={{ base: "none", md: "block" }}
        letterSpacing="0.04em"
        lineHeight="tall"
        mt="4"
        variant="small"
      >
        {readTimeAndOrDate}
      </Text>
    </Link>
  );
};

export default ArticleCard;

import {
  Heading,
  Container,
  Button,
  VStack,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC, useEffect } from "react";
import { InternalLink } from "src/atoms";
import Seo from "src/components/Core/Seo";
import { HEADER_HEIGHT_DESKTOP } from "src/components/Layout/Header/Header";

import Layout from "../components/Layout";
import { hasPagination, removePagination } from "../lib";

const NotFoundPage: FC<PageProps<Gatsby.NotFoundQuery>> = ({
  data: { datoCmsNotFoundPage },
}) => {
  if (!datoCmsNotFoundPage) return null;

  const location = useLocation();

  useEffect(() => {
    const { href } = location;
    const url = new URL(href);

    if (hasPagination(url.pathname)) {
      url.pathname = removePagination(url.pathname);

      // Refresh the page on purpose
      window.location.assign(url.toString());
    }
  }, []);

  const { seo, content, ctaLink, ctaLabel, illustration } = datoCmsNotFoundPage;

  return (
    <Layout>
      <Seo seo={seo!} />

      <Container>
        <Flex align="center" minH={`calc(100vh - ${HEADER_HEIGHT_DESKTOP}px)`}>
          <VStack maxW="560px" mx="auto" pb={44} pt={10} spacing="6">
            {illustration && (
              <Image
                alt={illustration.alt || ""}
                as={GatsbyImage}
                image={illustration.gatsbyImageData}
                maxW="335px"
              />
            )}

            <Heading
              as="h1"
              fontSize="font-48"
              lineHeight="shortest"
              textAlign="center"
            >
              {content}
            </Heading>

            {ctaLink && ctaLabel && (
              <Button
                as={InternalLink}
                paddingX={{ base: 4, md: 6 }}
                paddingY={{ base: 3, md: 3.5 }}
                to={ctaLink}
              >
                {ctaLabel}
              </Button>
            )}
          </VStack>
        </Flex>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFound {
    datoCmsNotFoundPage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      illustration {
        gatsbyImageData(placeholder: NONE)
        alt
      }
      content
      ctaLabel
      ctaLink
    }
  }
`;

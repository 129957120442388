import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Text,
  ContainerProps,
} from "@chakra-ui/react";
import { FC } from "react";
import { InternalLink } from "src/atoms";

type BreadcrumbArray = {
  title?: string;
  link: string;
};

const Breadcrumbs: FC<
  {
    crumbs?: BreadcrumbArray[];
    current?: BreadcrumbArray;
    currentOnly?: boolean;
  } & ContainerProps
> = ({ crumbs = [], current, currentOnly, ...rest }) => {
  const breadcrumbs = [{ title: "Accueil", link: "/" }, ...crumbs];

  return (
    <Container mb={{ base: 6, md: 10 }} {...rest}>
      <Breadcrumb lineHeight="normal">
        {!currentOnly &&
          breadcrumbs.map(({ title, link }) => (
            <BreadcrumbItem color="text.secondary" key={title}>
              <BreadcrumbLink
                _hover={{
                  color: "text.secondary",
                  textDecoration: "underline",
                }}
                as={InternalLink}
                to={link}
              >
                <Text fontSize="font-12">{title}</Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          ))}

        {current && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              color="text.primary"
              fontSize="font-12"
              href={current.link}
              textDecoration="underline"
            >
              {current.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </Container>
  );
};

export default Breadcrumbs;

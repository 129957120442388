const hasChangedLocation = (prevLocation: Location | null, location: Location) => {
  const { pathname: prevPathname } = prevLocation || {};
  const { pathname } = location;

  const isDifferentPathname = prevPathname !== pathname;

  return isDifferentPathname;
};

export { hasChangedLocation };

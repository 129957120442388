import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

const CustomArrow: FC<
  ButtonProps & {
    direction: "previous" | "next";
  }
> = ({ direction, ...rest }) => {
  const isPrev = direction === "previous";

  return (
    <Button
      as={Button}
      h={12}
      w={12}
      {...rest}
      _disabled={{ opacity: 0.5 }}
      _hover={{ bg: "transparent" }}
      bg="transparent"
      minW="unset"
      px="0"
      py="0"
    >
      <CustomIcon name={isPrev ? "arrowLeft" : "arrowRight"} />
    </Button>
  );
};

export default CustomArrow;

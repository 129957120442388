import { Box, Input, Button, BoxProps } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { FC, useState } from "react";
import { CustomIcon } from "src/atoms";

const SearchInput: FC<
  {
    buttonLeft?: boolean;
  } & BoxProps
> = (props) => {
  const [search, setSearch] = useState<string>("");

  const { placeholder, buttonLeft, ...rest } = props;

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    navigate(`/result/?q=${search}`);
  };

  return (
    <Box position="relative" {...rest}>
      <form onSubmit={handleSubmit}>
        <Input
          _focus={{ outline: "none" }}
          _hover={{ borderColor: "text.secondary" }}
          _placeholder={{
            color: "text.primary",
            fontSize: "font-12",
            lineHeight: "small",
          }}
          border="1px"
          borderColor="text.primary"
          borderRadius="full"
          color="text.primary"
          fontSize="font-12"
          h="13"
          onChange={(e) => setSearch(e.target.value)}
          p={buttonLeft ? "18px 24px 18px 50px" : "18px 50px 18px 24px"}
          placeholder={placeholder}
        />

        <Button
          _disabled={{ opacity: 1, cursor: "not-allowed" }}
          disabled={!search || search === ""}
          h="full"
          left={buttonLeft ? "0" : "unset"}
          pl={buttonLeft ? 6 : "unset"}
          position="absolute"
          pr={buttonLeft ? "unset" : 6}
          right={buttonLeft ? "unset" : "0"}
          top="calc(100% - 52px)"
          type="submit"
          variant="reset"
        >
          <CustomIcon
            name="magnifier"
            sx={{
              circle: { stroke: "text.primary" },
              rect: { stroke: "text.primary" },
            }}
          />
        </Button>
      </form>
    </Box>
  );
};

export default SearchInput;

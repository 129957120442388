import { FlexProps, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { CustomTag, InternalLink } from "src/atoms";

const Desktop: FC<
  FlexProps & {
    subCategories: { title: string; id: string; link: string }[];
  }
> = ({ subCategories, ...rest }) => {
  return (
    <Flex
      display={{ base: "none", md: "flex" }}
      flexWrap="wrap"
      mt="6"
      {...rest}
    >
      {subCategories.map((subCategory) => {
        return (
          <InternalLink key={subCategory.id} to={subCategory.link}>
            <CustomTag
              mb="4"
              mr="4"
              paddingLeft="6"
              paddingRight="6"
              paddingY="2"
              textTransform="none"
            >
              {subCategory.title}
            </CustomTag>
          </InternalLink>
        );
      })}
    </Flex>
  );
};

export default Desktop;

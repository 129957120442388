function getFormattedTitle(title: string): string {
  let formattedTitle = title

  if (title.length > 70) {
    formattedTitle = title.slice(0, 67) + "..."
  }

 return formattedTitle
}

export { getFormattedTitle };

import { Box, Heading, Container, HStack } from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import GuideCard from "src/components/Core/GuideCard";
import { isBrowser } from "src/services/browser";

import ArticleCard from "./ArticleCard";
import CustomButton from "./CustomButton";

const RelatedArticles = ({
  relatedArticles,
  title = `Ceci peut aussi vous intéresser`,
}: {
  relatedArticles:
    | readonly Gatsby.RelatedArticlesFragment[]
    | readonly Gatsby.RelatedGuidesFragment[];
  title?: string;
}) => {
  if (relatedArticles.length === 0) return null;

  const articlesLength = relatedArticles.length;

  const handleSlideChange = (next: number) => {
    setSlideIndex(next);
    setIsLast(checkIsLast(next));
  };

  const checkIsLast = (slideIndex: number) => {
    const lastIndexMobile = articlesLength / 2 > 0 ? articlesLength - 2 : 0;
    const lastIndexDesktop = articlesLength / 3 > 0 ? articlesLength - 3 : 0;
    const lastIndexXl = articlesLength / 4 > 0 ? articlesLength - 4 : 0;
    let last = false;

    if (isBrowser()) {
      const windowWidth = window.innerWidth;
      let lastIndex = lastIndexMobile;

      if (windowWidth >= 750) {
        lastIndex = windowWidth <= 1280 ? lastIndexDesktop : lastIndexXl;
      }

      if (slideIndex >= lastIndex) {
        last = true;
      } else {
        last = false;
      }
    }

    return last;
  };

  const [slideIndex, setSlideIndex] = useState<number | null>(0);
  const [isLast, setIsLast] = useState<boolean | null>(
    checkIsLast(slideIndex!)
  );

  useEffect(() => {
    window.addEventListener("resize", () =>
      setIsLast(checkIsLast(slideIndex!))
    );

    return () => {
      window.removeEventListener("resize", () =>
        setIsLast(checkIsLast(slideIndex!))
      );
    };
  });

  const sliderRef = useRef<HTMLDivElement & Slider>(null);
  const settings = {
    slidesToShow: 4,
    infinite: false,
    draggable: false,
    beforeChange: (current: number, next: number) => handleSlideChange(next),
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Container overflow={{ base: "hidden", lg: "visible" }} variant="block">
      <Container overflow="visible">
        <HStack
          alignItems="center"
          justifyContent="space-between"
          mb={{ base: "4", md: "10" }}
          overflow="visible"
        >
          <Heading
            fontWeight="medium"
            ml={{ base: "-0.5", md: "-2.5" }}
            px={{ base: "0", md: "10px" }}
            variant="heading-2"
          >
            {title}
          </Heading>

          <HStack
            display={{
              base: articlesLength > 2 ? "flex" : "none",
              md: articlesLength > 3 ? "flex" : "none",
              xl: articlesLength > 4 ? "flex" : "none",
            }}
            h={{ base: 10, md: 14 }}
            overflow="visible"
            spacing="2"
          >
            <CustomButton
              isDisabled={slideIndex === 0}
              onClick={() => sliderRef.current?.slickPrev()}
            />

            <CustomButton
              direction={"next"}
              isDisabled={isLast === true}
              onClick={() => sliderRef.current?.slickNext()}
            />
          </HStack>
        </HStack>

        <Box
          as={Slider}
          {...settings}
          ml={{ base: "-2", md: "-5" }}
          ref={sliderRef}
          sx={{ ".slick-track": { ml: 0 } }}
          w={{ base: "calc(100% + 16px)", md: "calc(100% + 40px)" }}
        >
          {relatedArticles?.map((article) => {
            return (
              <Box key={article.id} px={{ base: 2, md: 4 }}>
                {article.__typename === "DatoCmsArticle" ? (
                  <ArticleCard {...article!} />
                ) : (
                  <GuideCard {...article!} noScale />
                )}
              </Box>
            );
          })}
        </Box>
      </Container>
    </Container>
  );
};

export default RelatedArticles;

import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  UseDisclosureReturn,
  ListItem,
  List,
  Link,
  Heading,
  Box,
  Text,
  Image,
  Spacer,
  VStack,
  chakra
} from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC, Fragment } from "react";
import semanticColors from "src/@chakra-ui/gatsby-plugin/foundations/colors/semanticColors"
import { CustomTag, CustomIcon, InternalLink } from "src/atoms";

import MobileSubscribe from "./MobileSubscribe";

type MobileDrawerProps = Pick<UseDisclosureReturn, "isOpen" | "onClose">;

const MobileDrawer: FC<MobileDrawerProps & { articleCategorySlug?: string }> =
  ({ isOpen, onClose }) => {
    const { navigation } = useStaticQuery<Gatsby.MobileDrawerQuery>(
      graphql`
        query MobileDrawer {
          navigation: datoCmsNavigation {
            ...DatoCmsNavigation
          }
          subCategories: allDatoCmsSubCategory {
            nodes {
              title
              slug
              id
              category {
                id
              }
            }
          }
        }
      `
    );

    const renderDrawerItems = (
      item:
        | Gatsby.NavigationBlockCategoryFragment
        | Gatsby.NavigationBlockTagFragment
        | Gatsby.DatoCmsLinkFragment
        | Gatsby.ExternalLinkFragment
    ) => {
      const { __typename: type } = item;

      if (type === "DatoCmsCategory") {
        return (
          <ListItem mb="4" pl="4">
            <InternalLink to={item.slug || ""}>
              <Text
                color={"text.primary"}
                fontWeight="inherit"
                lineHeight="tall"
              >
                {item.name}
              </Text>
            </InternalLink>
          </ListItem>
        );
      }

      if (type === "DatoCmsSidebarExternalLink") {
        return (
          <ListItem mb="4" pl="4">
            <Link href={item.url}>
              <Text fontWeight="inherit" lineHeight="tall">
                {item.label}
              </Text>
            </Link>
          </ListItem>
        );
      }

      if (type === "DatoCmsLink") {
        return (
          <ListItem display="inline-block" mb="2" mr="2">
            <InternalLink to={item.link || ""}>
              <CustomTag fontSize="font-12">{item.label}</CustomTag>
            </InternalLink>
          </ListItem>
        );
      }
    };

    return (
      <Drawer isFullHeight isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />

        <DrawerContent
          boxShadow={`5px 0px 45px 0px ${semanticColors.shine.yellow}`}
          maxW="327px"
          px={8}
          py={6} //1px margin top is necessary for the nav to not collapse with overlay's border top
        >
          <chakra.span
            borderTop="1px solid"
            borderTopColor="grey.400"
            left="0"
            position="fixed"
            top="0"
            w="100vw"
          />

          <DrawerCloseButton right={5} top={6}>
            <CustomIcon h="12px" name="closeNav" w="12px"/>
          </DrawerCloseButton>

          <DrawerBody p="0">
            <VStack align="start" h="full" justify="flex-start" spacing="0">
              {navigation?.navigationBlocks?.map((block) => {
                const { title, links } = block!;

                return (
                  <Box key={block?.id}>
                    <Heading mb={4} variant="subtitle-large">
                      {title}
                    </Heading>

                    <List mb={6}>
                      {links?.map((link) => {
                        return (
                          <Fragment key={link?.id}>
                            {renderDrawerItems(link!)}
                          </Fragment>
                        );
                      })}
                    </List>
                  </Box>
                );
              })}

              {navigation?.emailSubscribe && (
                <MobileSubscribe {...navigation.emailSubscribe} />
                )}

              <Spacer />

              {navigation?.companyLogo && (
                <Link href={process.env.GATSBY_SHINE_URL} isExternal>
                  <Image src={navigation.companyLogo.url} />
                </Link>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  };

export default MobileDrawer;

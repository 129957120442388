import { Box, Container, Heading, Text, Stack, Image } from "@chakra-ui/react";
import { FC } from "react";

const Intro: FC<Gatsby.CollectionDataFragment> = ({ name, icon, intro }) => {
  return (
    <Container mt={2}>
      <Box maxW="711px">
        <Stack direction="row" spacing={{ base: 4, md: 10 }}>
          <Heading variant="heading-1">{name}</Heading>

          {icon && (
            <Box maxH="104px" maxW="104px">
              <Image alt={icon.alt || ""} src={icon.url} />
            </Box>
          )}
        </Stack>

        <Text mt={10} variant="article">
          {intro}
        </Text>
      </Box>
    </Container>
  );
};

export default Intro;

export default function getSectionId(heading: string) {
  const sectionId = heading.toLowerCase()
    .replace(/[*+~.()'"!:@?]/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .replace(/%/, 'percent')
    .normalize('NFKD')
    .replace(/[^\w-]/g, '');

  return sectionId;
}

import { Flex, FlexProps } from "@chakra-ui/react";
import { FC } from "react";
import { InternalLink } from "src/atoms";

const PaginateButton: FC<
  FlexProps & {
    link: string;
    page: number;
    isCurrent?: boolean;
  }
  > = ({ link, page, isCurrent }) => {
  const dimensions = { base: 10, md: 12 };

  return (
    <Flex
      _hover={{
        background: "shine.yellow",
        color: "text.primary"
      }}
      alignItems="center"
      as={InternalLink}
      background={isCurrent ? "text.primary" : "transparent"}
      border="1px solid"
      borderColor="text.primary"
      borderRadius="50%"
      color={isCurrent ? "white" : "text.primary"}
      justifyContent="center"
      maxH={dimensions}
      maxW={dimensions}
      minH={dimensions}
      minW={dimensions}
      to={link}
    >
      {page}
    </Flex>
  );
};

export default PaginateButton;

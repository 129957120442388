import { Image, Box, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { variableTransformer } from "src/services/metadata/variable";

const DatoCmsFullWidthImage: FC<Gatsby.DatoCmsFullWidthImageFragment> = ({
  media,
  caption,
}) => {
  return (
    <Box my={{ base: "6", lg: "10" }}>
      <Image
        alt={media?.alt || ""}
        as={GatsbyImage}
        image={media?.gatsbyImageData}
        imgStyle={{ borderRadius: "16px" }}
      />

      {caption && (
        <Text color="text.cta" mt={4} textAlign="center" variant="small">
          {variableTransformer(caption)}
        </Text>
      )}
    </Box>
  );
};

export default DatoCmsFullWidthImage;

const CURRENT_YEAR_VAR = "%currentYear%"
const currentYear = new Date().getFullYear().toString()

function variableTransformer(text: string):string {
  const re = new RegExp(CURRENT_YEAR_VAR, "g");
  const updatedText = text.replace(re, currentYear)

  return updatedText;
}

export { variableTransformer };

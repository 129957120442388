import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import { FC } from "react";

import { linkResolver } from "./utils";

type CustomGatsbyLinkProps = Omit<GatsbyLinkProps<any>, "ref">;

const InternalLink: FC<CustomGatsbyLinkProps> = ({ to, children, ...rest }) => {
  const link = linkResolver(to);

  return (
    <GatsbyLink to={link} {...rest}>
      {children}
    </GatsbyLink>
  );
};

export default InternalLink;

function getBreadcrumbs(
  categories: { title: string; slug: string }[]
): { title: string; link: string }[] {
  const crumbs = categories?.map(({ title }, index) => {
    let link = "/"
    
    categories.slice(0, index + 1).forEach((current) => link = link + current.slug + "/")

    return {
      title,
      link,
    };
  });

  return crumbs;
}

export { getBreadcrumbs };

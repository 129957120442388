import { Text, Box, Image, Flex, Link } from "@chakra-ui/react";
import { graphql, useStaticQuery } from "gatsby";
import { FC, useState, Fragment } from "react";
import { CustomIcon, InternalLink } from "src/atoms";

import MobileDrawer from "./components/MobileDrawer";
import MobileSearch from "./components/MobileSearch";

export const HEADER_HEIGHT_MOBILE = 62
export const HEADER_HEIGHT_DESKTOP = 73

const Header: FC<{
  articleCategorySlug?: string;
}> = ({ articleCategorySlug }) => {
  const { header } = useStaticQuery<Gatsby.HeaderQuery>(
    graphql`
      query Header {
        header: datoCmsHeader {
          ...DatoCmsHeader
        }
      }
    `
  );

  if (!header) {
    return null;
  }

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const icons = [
    {
      icon: <CustomIcon cursor="pointer" name="magnifier"/>,
      onClick: () => setSearchOpen(true),
      id: "magnifier",
    },
    {
      icon: <CustomIcon cursor="pointer" name="burger" />,
      onClick: () => setMobileMenuOpen(true),
      id: "burder",
    },
  ];

  return (
    <Fragment>
      <Box
        maxW="100vw"
        pointerEvents="none"
        position="fixed"
        top="0"
        w="full"
        zIndex="10"
      >
        <Box
          background="white"
          borderBottom="1px"
          borderColor="grey.400"
          pointerEvents="auto"
        >
          <Box
            as="header"
            margin="auto"
            maxW={"container.max"}
            padding={{
              base: "8px 4px 8px 16px",
              lg: "12px 24px",
            }}
            w="100%"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Box
                alignSelf={{ base: "center", md: "flex-start" }}
                mt={{ base: 0, md: 0.5 }}
              >
                <Link as={InternalLink} to={"/"}>
                  <Image
                    alt={header.logo?.alt || ""}
                    maxH={{ base: "22px", md: "29px" }}
                    src={header.logo?.url}
                  />
                </Link>

                <Text
                  color="grey.600"
                  display={{ base: "none", md: "block" }}
                  fontSize="font-12"
                  fontWeight="medium"
                  lineHeight="none"
                >
                  {header.title}
                </Text>
              </Box>

              <Flex
                alignItems="center"
                alignSelf={{ md: "flex-end" }}
                justifyContent={{ base: "space-between", md: "flex-end" }}
              >
                <Link
                  display="block"
                  href={process.env.GATSBY_SHINE_URL}
                  isExternal
                  mx={{ base: 3, lg: 0 }}
                >
                  <Image
                    alt={header.companyLogo?.alt || ""}
                    h={{ base: "46px", md: "48px" }}
                    src={header.companyLogo?.url}
                  />
                </Link>

                <Flex
                  alignItems="center"
                  alignSelf={{ md: "flex-end" }}
                  justifyContent="flex-end"
                >
                  {icons.map(({ icon, onClick, id }) => (
                    <Box
                      _active={{ bg: "transparent" }}
                      _hover={{ bg: "transparent" }}
                      alignItems="center"
                      as="button"
                      bg="transparent"
                      display={{ base: "flex", lg: "none" }}
                      key={id}
                      onClick={onClick}
                      padding="3"
                    >
                      {icon}
                    </Box>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>

      <MobileDrawer
        articleCategorySlug={articleCategorySlug}
        isOpen={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      />

      <MobileSearch isOpen={searchOpen} onClose={() => setSearchOpen(false)} />
    </Fragment>
  );
};

export default Header;

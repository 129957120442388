export default function scrollToSection(id: string, e?: React.MouseEvent, block?: "center" | "start") {
  e?.preventDefault();

  const target = document.getElementById(id);

  if (target) {
    target.scrollIntoView({
      behavior: "smooth",
      block: block || "center",
      inline: "nearest",
    });
    window.history.replaceState(null, "", `#${encodeURIComponent(id)}`);
  }
}

import { Image, Box, LinkBoxProps } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { InternalLink } from "src/atoms";

const GuideCard: FC<Gatsby.DatoCmsGuideCardFragment & LinkBoxProps & {noScale?: boolean}> = ({
  illustration,
  title,
  body,
  slug,
  date,
  noScale,
  ...rest
}) => {
  return (
    <Box
      as={InternalLink}
      to={`/${slug}/`}
      {...rest}
      borderRadius="2xl"
      overflow="hidden"
    >
      <Image
        _hover={{
          transform: noScale ? "" : "scale(1.03)",
        }}
        alt={illustration?.alt || ""}
        as={GatsbyImage}
        image={illustration?.thumbnail}
        imgStyle={{
          width: "100%",
          height: "100%",
          borderRadius: "16px",
          transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1)",
        }}
        transitionDuration="normal"
        transitionProperty="common"
        transitionTimingFunction="ease-out"
      />
    </Box>
  );
};

export default GuideCard;

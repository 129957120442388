import { Container, Heading, Box } from "@chakra-ui/react";
import { FC } from "react";

import ArticleCard from "../../Core/ArticleCard";
import ArticleGrid from "../../Core/ArticleGrid";
import CtaCard from "../../Core/ArticleGrid/CtaCard";
import FeaturedArticle from "../../Core/FeaturedArticle";

type ArticleListProps = {
  articles?: Gatsby.DatoCmsArticleCardFragment[];
  ctaCard?: Gatsby.DatoCmsCtaCardFragment;
};

const ArticleList: FC<ArticleListProps> = ({ articles, ctaCard }) => {
  if (!articles) return null;

  const mostRecentArticle = articles[0]!;
  const articlesList = articles.slice(1, articles.length);

  const list: (
    | Gatsby.DatoCmsArticleCardFragment
    | Gatsby.DatoCmsCtaCardFragment
  )[] = [...articlesList];

  if (ctaCard) {
    list.splice(7, 0, ctaCard!);
  }

  return (
    <Container variant="block">
      <Container>
        <Heading
          as="h1"
          fontSize="font-28"
          fontWeight={{ base: "semibold", md: "medium" }}
          mb={{ base: 4, md: 6 }}
          variant="heading-1"
        >
          Derniers articles
        </Heading>

        <Box mb={{ base: 7, md: 8 }}>
          <FeaturedArticle {...mostRecentArticle} />
        </Box>

        <ArticleGrid>
          {list.map((card, index) => {
            if (card.__typename === "DatoCmsArticle") {
              const visibleSm = index <= 2;
              const visibleMd = index <= 4;

              return (
                <ArticleCard
                  {...card!}
                  display={{
                    base: visibleSm ? "block" : "none",
                    md: visibleMd ? "block" : "none",
                    xl: "block",
                  }}
                  key={card.id}
                />
              );
            } else {
              return <CtaCard {...card} key={card.id} />;
            }
          })}
        </ArticleGrid>
      </Container>
    </Container>
  );
};

export default ArticleList;

import { BreadcrumbListMarkupType, PersonMarkupType } from "./types";

export const breadcrumbMarkup = (
  crumbs: Array<{ title: string; link: string }>,
  page: { name: string, path: string },
): BreadcrumbListMarkupType => {
  const { name, path } = page;

  return {
    itemListElement: [
      { name: 'Blog', url: `${process.env.GATSBY_SHINE_URL}blog/` },
      ...crumbs.map(({ title, link }) => ({ name: title, url: `${process.env.GATSBY_SHINE_URL}blog${link}` })),
      { "@type": "ReadAction", name, url: `${process.env.GATSBY_SHINE_URL}blog${path}` },
    ]
  }
}

export const personMarkup = (author: Gatsby.DatoCmsAuthor): PersonMarkupType => {
  return {
    name: author.name,
    description: author.description,
    url: `${process.env.GATSBY_SHINE_URL}auteur/${author.slug}`,
    sameAs: [author.twitter, author.linkedin, author.facebook, author.website].filter(Boolean),
    jobTitle: author.job,
    knowsAbout: author.knowsAbout,
    worksFor: 'Shine',
  }
}

import { Flex, Heading, Link } from "@chakra-ui/react";
import { FC } from "react";
import { CustomIcon } from "src/atoms";

const SocialMedia: FC<Gatsby.DatoCmsFooterFragment> = ({ title, socialMedias }) => {
  return (
    <Flex
      alignItems={{ base: "center", lg: "flex-start" }}
      direction="column"
      justifyContent="flex-start"
      mt={{ base: 20, lg: 10 }}
      >
      <Heading
        fontSize="font-20"
        fontWeight="semibold"
        lineHeight="base"
        variant="heading-3"
      >
        {title}
      </Heading>

      <Flex direction="row" flexWrap="wrap" mt="6" spacing="4">
        {socialMedias?.map((platform) => {
          const { name, url } = platform!;

          return (
            <Link href={url} isExternal key={url}>
              <Flex
                alignItems="center"
                h="40px"
                justifyContent="flex-start"
                w="40px"
              >
                <CustomIcon
                  name={name}
                  sx={{
                    path: {
                      fill: "grey.700",
                    },
                  }}
                />
              </Flex>
            </Link>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default SocialMedia;

import { FC } from "react";

import DatoCmsHubspotFormIntegration from "./DatoCmsHubspotFormIntegration";

const DatoCmsHubspotForm: FC<Gatsby.DatoCmsHubspotFormFragment> = ({
  id,
  formId,
  inlineMessage,
  redirectUrl
}) => {
  if (!formId || !id) {
    return null;
  }

  return <DatoCmsHubspotFormIntegration
    formId={formId}
    inlineMessage={inlineMessage}
    recordId={id}
    redirectUrl={redirectUrl}
  />
};

export default DatoCmsHubspotForm;

import { useLocation } from "@reach/router";

function isBrowser(): boolean {
  return typeof window !== "undefined";
}

function isHomepage(): boolean {
  const location = useLocation();

  const isHomepage =
    location.hostname === "localhost"
      ? location.pathname === "/"
      : location.pathname === "/copilote/";

  return isHomepage;
}

export { isBrowser, isHomepage };

import { StructuredTextDocument, render } from 'datocms-structured-text-to-plain-text'

import { Cell, TableOptions } from "./types";

const isTopLeftCellEmpty = (options: TableOptions, head: Cell[] | null = null) => {
  if (options.mainRow !== 0 || options.mainColumn !== 0) {
    return false
  }

  if (head?.[0]?.value && render(head[0].value as StructuredTextDocument)) {
    return false
  }

  return true
}

export { isTopLeftCellEmpty };

function getReadTime(body: string, onlyTime = false): string {
  if (!body) return "";

  const readTime = Math.max(1, Math.round(body.split(" ").length / 200));

  let readTimeString = readTime + " min. de lect";

  if (onlyTime) {
    readTimeString = readTime + " min"
  }

  return readTimeString;
}

export { getReadTime };

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Img,
  Box,
  Heading,
  CSSObject,
  Interpolation,
} from '@chakra-ui/react'
import { render } from 'datocms-structured-text-to-plain-text'
import { CSSProperties, FC, Fragment } from "react"
import { CustomIcon } from 'src/atoms'
import StructuredText from 'src/components/StructuredText'

import { Cell, CellType, Table as TableType } from './types'
import { isTopLeftCellEmpty } from './utils'

const DatoCmsTable: FC<Gatsby.DatoCmsTableFragment> = ({ table }) => {
  if (!table) {
    return null
  }

  const value = JSON.parse(table) as TableType
  const { title, options, data } = value;

  const head = options.mainRow === 0 ? data.shift() : null

  const renderCell = (cell: Cell) => {
    if (cell.type === CellType.Image) {
      return <Img alt="" src={cell.value.attributes.url} />
    }

    const plainValue = render(cell.value);
    const isEmoji = plainValue ? /^(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)$/u.test(plainValue) : false;

    if (isEmoji) {
      return <Text fontSize="font-32">{plainValue}</Text>
    }

    return <StructuredText data={cell.value} />
  }

  return (
    <Fragment>
      {title ? <Heading sx={s.title}>{title}</Heading> : null}

      <Box css={s.mobileScroll as Interpolation<CSSProperties>} display={{ lg: "none" }}>
        <Box sx={s.mobileScrollLine} />

        <CustomIcon name="swipeRight" style={s.mobileScrollIcon as CSSProperties} />
      </Box>

      <TableContainer mb="40px" whiteSpace={{ lg: 'initial' }}>
        <Table
          size="sm"
          sx={{
            ...s.table,
            ...(options.mainColumn === -1 ? s.tableStriped : {}),
            ...(isTopLeftCellEmpty(options, head) ? s.tableTopLeftEmpty : {})
          }}
          variant="simple"
        >

          {head ? (
            <Thead>
              <Tr
                alignItems="center"
                justifyContent="center"
              >
                {head.map((cell: Cell) => (
                  <Th
                    backgroundColor="var(--chakra-colors-grey-200)"
                    key={cell.id}
                    sx={s.tableHeading}
                    textTransform="initial"
                  >
                    {renderCell(cell)}
                  </Th>
                ))}
              </Tr>
            </Thead>
          ) : null}

          <Tbody className={!head ? "no-thead" : ""}>
            {data.map((row: Array<Cell>, i: number) => {
              const isMainRow = head === null && options.mainRow === i

              return (
                <Tr key={i}>
                  {row.map((cell: Cell, j: number) => {
                    const isMainColumn = options.mainColumn === j

                    return (
                      <Td
                        backgroundColor={(isMainRow || isMainColumn) ? "var(--chakra-colors-grey-200)" : "inherit"}
                        key={cell.id}
                        sx={isMainColumn ? s.tableHeadingColumn : s.tableText}
                      >
                        {renderCell(cell)}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>

        </Table>
      </TableContainer>
    </Fragment>
  )
}

const s: Record<string, CSSObject | CSSProperties> = {
  mobileScroll: {
    position: 'relative',
    display: "flex",
    justifyContent: "center",
    marginBottom: "12px",
  },
  mobileScrollLine: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: '12px',
    width: '100%',
    height: '1px',
    backgroundColor: '#eee',
  },
  mobileScrollIcon: {
    zIndex: 2,
    width: '24px',
    height: '24px',
    backgroundColor: "#fff",
  },
  title: {
    marginBottom: "32px",
    marginTop: "40px",
    fontSize: "font-20",
    lineHeight: "30px",
    fontWeight: "600",
    textAlign: "center",
  },
  table: {
    tableLayout: "fixed",
    borderSpacing: 0,
    borderCollapse: 'separate',
    'thead th': {
      border: '1px solid var(--chakra-colors-grey-500)',
      borderRight: 'none',
    },
    'thead tr:first-child th:first-child, .no-thead tr:first-child td:first-child': {
      borderTopLeftRadius: '8px',
    },
    'thead tr:first-child th:last-child, .no-thead tr:first-child td:last-child': {
      borderTopRightRadius: '8px',
      borderRight: '1px solid var(--chakra-colors-grey-500)',
    },
    '.no-thead tr:first-child td': {
      borderTop: '1px solid var(--chakra-colors-grey-500)',
    },
    'tbody td': {
      minWidth: '100px',
      border: '1px solid var(--chakra-colors-grey-500)',
      borderTop: 'none',
      borderRight: 'none',
    },
    'tbody td:last-child': {
      borderRight: '1px solid var(--chakra-colors-grey-500)',
    },
    'tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: '8px',
    },
    'tbody tr:last-child td:last-child': {
      borderBottomRightRadius: '8px',
    },
    '@media (max-width: 62em)': {
      tableLayout: "initial",
    },
  },
  tableStriped: {
    'tbody tr:nth-child(even)': {
      backgroundColor: "var(--chakra-colors-grey-200)",
    },
  },
  tableTopLeftEmpty: {
    'thead th:first-child': {
      backgroundColor: "transparent",
      border: "none",
    },
    'thead th:first-child + th': {
      borderTopLeftRadius: '8px',
    },
    'tbody tr:first-child td:first-child': {
      borderTop: '1px solid var(--chakra-colors-grey-500)',
      borderTopLeftRadius: '8px',
    },
  },
  tableHeading: {
    padding: "16px 8px",
    'p': {
      margin: 0,
      fontSize: "16px",
      lineHeight: "18px",
      letterSpacing: "initial",
      fontFamily: "var(--chakra-fonts-heading)",
      fontWeight: "400",
      textAlign: "center",
      color: "var(--chakra-colors-grey-800)",
    },
    '@media (max-width: 62em)': {
      'p': {
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: "600",
      },
    },
  },
  tableHeadingColumn: {
    padding: "16px 8px",
    'p': {
      margin: 0,
      fontSize: "16px",
      lineHeight: "18px",
      letterSpacing: "initial",
      fontFamily: "var(--chakra-fonts-heading)",
      fontWeight: "400",
      textAlign: "center",
      color: "var(--chakra-colors-grey-800)",
    },
    '@media (max-width: 62em)': {
      position: "sticky",
      left: 0,
      'p': {
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: "600",
        textAlign: "left",
      },
    },
  },
  tableText: {
    padding: "8px 16px",
    'p': {
      margin: 0,
      fontSize: "12px",
      lineHeight: "1.38",
      fontWeight: "400",
      textAlign: "center",
      color: "var(--chakra-colors-grey-800)",
    },
  },
}

export default DatoCmsTable

// Something looks wrong with the type Person from this lib.
// See: https://github.com/google/schema-dts/issues/28#issuecomment-522774793
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PersonMarkupType = any;

const getPersonMarkup = ({
  name,
  description,
  url,
  sameAs,
  ...rest
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: PersonMarkupType): any => {
  return {
    "@type": "Person",
    "@id": `${url}#schema-author`,
    name,
    description,
    url,
    sameAs,
    ...rest,
  };
};

export default getPersonMarkup;

import { Box, List, Heading, ListItem, chakra, Link } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import { Fragment, FC } from "react";
import { CustomTag, InternalLink } from "src/atoms";
import { getCategoryLink } from "src/services/categories/link";

const Section: FC<
  Gatsby.NavigationBlocksFragment & { articleCategorySlug?: string }
> = ({ title, links, articleCategorySlug }) => {
  const location = useLocation();
  const renderListItem = (
    item:
      | Gatsby.NavigationBlockCategoryFragment
      | Gatsby.NavigationBlockTagFragment
      | Gatsby.DatoCmsLinkFragment
      | Gatsby.ExternalLinkFragment
  ) => {
    switch (item.__typename) {
      case "DatoCmsCategory": {
        const isActive =
          location.pathname.includes(item.slug!) ||
          articleCategorySlug?.includes(item.slug!);

        return (
          <ListItem
            _last={{ mb: 0 }}
            color={item?.isBlue ? "text.secondary" : "text.primary"}
            fontFamily="heading"
            fontSize="font-12"
            lineHeight="tallest"
            mb="2"
            paddingLeft="4"
            sx={{ ".active": { ".line": { w: "full" } } }}
          >
            <Box
              as={InternalLink}
              className={isActive ? "active" : ""}
              position="relative"
              role="group"
              to={getCategoryLink(item.slug || "")}
              width="fit-content"
            >
              {item.name}

              <chakra.span
                _groupHover={{
                  width: "100%",
                  left: "0",
                }}
                bg="grey.600"
                bottom="-5px"
                className="line"
                h="1px"
                position="absolute"
                right="0"
                transitionDuration="normal"
                transitionProperty="dimensions"
                transitionTimingFunction="ease-out"
                w="0"
              />
            </Box>
          </ListItem>
        );
      }

      case "DatoCmsLink": {
        return (
          <ListItem display="inline-block" mb="2" mr="2">
            <InternalLink to={item.link || ""}>
              <CustomTag fontSize="font-12">{item.label}</CustomTag>
            </InternalLink>
          </ListItem>
        );
      }

      case "DatoCmsSidebarExternalLink": {
        return (
          <ListItem
            _last={{ mb: 0 }}
            color="text.secondary"
            fontFamily="heading"
            fontSize="font-12"
            lineHeight="tallest"
            mb="3"
            paddingLeft="4"
          >
            <Box
              _hover={{
                textDecoration: "none",
              }}
              as={Link}
              href={item.url}
              position="relative"
              role="group"
              width="fit-content"
            >
              {item.label}

              <chakra.span
                _groupHover={{
                  width: "100%",
                  left: "0",
                }}
                bg="grey.600"
                bottom="-5px"
                h="1px"
                position="absolute"
                right="0"
                transitionDuration="normal"
                transitionProperty="dimensions"
                transitionTimingFunction="ease-out"
                w="0"
              />
            </Box>
          </ListItem>
        );
      }
    }
  };

  return (
    <Box mb="10">
      <Heading as="div" mb={4} variant="subtitle-medium">
        {title}
      </Heading>

      <List>
        {links?.map((link) => {
          return <Fragment key={link?.id}>{renderListItem(link!)}</Fragment>;
        })}
      </List>
    </Box>
  );
};

export default Section;
